<template>
    <div>
        <template v-if="source.type === 'browser_source'">
            <div class="row">
                <div class="col-12">
                    <select
                        v-model="value"
                        class="form-control form-control-sm"
                    >
                        <option value="">{{ $t('No selected') }}</option>
                        <option
                            v-for="(item, index) in streams"
                            :key="`source-option-${index}`"
                            :value="item.stream"
                        >
                            {{ item.speaker.name }} ({{ item.type }})
                            <span v-if="item.speaker.is_presenter"> - {{ $t('Presenter') }}</span>
                        </option>
                    </select>
                </div>
                <template v-if="streamSelected && streamSelected.type === 'desktop'">
                    <div class="col-12 col-md-4 mt-2">
                        <select
                            v-model="codecSelected"
                            class="form-control form-control-sm"
                        >
                            <option value="">{{ $t('No codec') }}</option>
                            <option
                                v-for="codec in codecs"
                                :key="`codec-selected-${codec}`"
                                :value="codec"
                            >
                                {{ $t('Codec') }} {{ codec }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-4 mt-2">
                        <select
                            v-model="scaleSelected"
                            class="form-control form-control-sm"
                        >
                            <option value="">{{ $t('No scale') }}</option>
                            <option
                                v-for="scale in scales"
                                :key="`scale-selected-${scale}`"
                                :value="scale"
                            >
                                {{ $t('Scale') }} {{ scale }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-4 mt-2">
                        <select
                            v-model="bitRateSelected"
                            class="form-control form-control-sm"
                        >
                            <option value="">{{ $t('Bitrate variable') }}</option>
                            <option
                                v-for="bitRate in bitRates"
                                :key="`bitRate-selected-${bitRate}`"
                                :value="bitRate"
                            >
                                {{ $t('Bitrate') }} {{ bitRate }}
                            </option>
                        </select>
                    </div>
                </template>
                <div class="col-12 mt-2 text-right">
                    <button
                        class="btn btn-sm btn-success"
                        @click="handleApply"
                    >
                        <i class="fa fa-check" />
                    </button>
                </div>
            </div>
        </template>
        <template v-else-if="isText">
            <div class="input-group input-group-sm">
                <input
                    v-model="value"
                    class="form-control"
                />
                <div class="input-group-append">
                    <button
                        class="btn btn-sm btn-success"
                        @click="handleApply"
                    >
                        <i class="fa fa-check" />
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'SceneSourceEdit',
        props: {
            source: {
                type: Object,
                required: true
            },
            streams: {
                type: Array,
                required: false,
                default: Array
            },
            currentStream: {
                type: Object,
                required: false,
                default: null
            },
            settings: {
                type: Object,
                required: false,
                default: null
            },
            codecs: {
                type: Array,
                required: true
            },
            scales: {
                type: Array,
                required: true
            },
            bitRates: {
                type: Array,
                required: true
            }
        },
        data () {
            return {
                value: this.getDefaultValue(),
                codecSelected: this.getDefaultCodec(),
                scaleSelected: this.getDefaultScale(),
                bitRateSelected: this.getDefaultBitRate()
            }
        },
        computed: {
            isText () {
                return ['text_gdiplus_v2', 'text_ft2_source_v2'].includes(this.source.type)
            },
            streamSelected () {
                if ( !this.value || this.source.type !== 'browser_source' ) {
                    return null
                }

                return this.streams.find(item => item.stream === this.value)
            }
        },
        methods: {
            getDefaultValue () {
                switch ( this.source.type ) {
                    case 'browser_source':
                        return this.currentStream
                            ? this.currentStream.stream
                            : ''
                    case 'text_gdiplus_v2':
                    case 'text_ft2_source_v2':
                        return this.settings.text
                    default:
                        return ''
                }
            },
            getDefaultCodec () {
                if ( this.source.type === 'browser_source' ) {
                    for ( const codec of this.codecs ) {
                        if ( this.settings.url.includes(codec) ) {
                            return codec
                        }
                    }
                }

                return ''
            },
            getDefaultScale () {
                if ( this.source.type === 'browser_source' ) {
                    for ( const scale of [...this.scales].reverse() ) {
                        if ( this.settings.url.includes(`&scale=${scale}`) ) {
                            return scale
                        }
                    }
                }

                return ''
            },
            getDefaultBitRate () {
                if ( this.source.type === 'browser_source' ) {
                    for ( const bitRate of [...this.bitRates].reverse() ) {
                        if ( this.settings.url.includes(`&bitrate=${bitRate}`) ) {
                            return bitRate
                        }
                    }
                }

                return ''
            },
            getValue () {
                switch ( this.source.type ) {
                    case 'browser_source':
                        if ( this.streamSelected && this.streamSelected.type === 'desktop' ) {
                            const codec = this.codecSelected ? `&codec=${this.codecSelected}` : ``
                            const scale = this.scaleSelected ? `&scale=${this.scaleSelected}` : ``
                            const bitRate = this.bitRateSelected ? `&bitrate=${this.bitRateSelected}` : ``

                            return {
                                url: `${this.value}${codec}${scale}${bitRate}`
                            }
                        }

                        return { url: this.value }
                    case 'text_gdiplus_v2':
                    case 'text_ft2_source_v2':
                        return { text: this.value }
                    default:
                        return {}
                }
            },
            handleApply () {
                this.$emit('onApply', this.getValue())
            }
        }
    }
</script>
