<template>
    <div
        ref="canvas"
        class="position-relative light"
        :style="`height: ${canvas.height}px`"
    >
        <template v-if="loaded">
            <scene-preview-item
                v-for="(item, index) of items"
                :key="`preview-item-${index}`"
                :ref="`previewItem${index}`"
                :item="item"
                :width="item.canvas.width"
                :height="item.canvas.height"
                :x-position="item.canvas.x"
                :y-position="item.canvas.y"
                :rotation="item.properties.rotation"
            />
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ScenePreviewItem from './ScenePreviewItem'
    import obsMixin from '../../mixins/obs'

    export default {
        name: 'ScenePreview',
        components: { ScenePreviewItem },
        mixins: [ obsMixin ],
        props: {
            obs: {
                type: Object,
                required: true
            },
            scene: {
                type: String,
                required: true,
            },
            sources: {
                type: Array,
                required: false,
                default: Array
            }
        },
        data () {
            return {
                sourceTypesAvailable: ['browser_source', 'image_source', 'color_source_v2', 'text_ft2_source_v2', 'text_gdiplus_v2', 'ffmpeg_source'],
                loaded: false,
                videoInfo: null,
                items: [],
                canvas: {
                    scale: 0,
                    width: 0,
                    height: 0
                }
            }
        },
        computed: {
            ...mapState({
                sourceCollapse: state => state.sourceCollapse
            })
        },
        watch: {
            scene () {
                this.loadPreview()
            },
            sourceCollapse () {
                this.loadPreview()
            }
        },
        mounted () {
            window.addEventListener('resize', this.loadCanvas)
            this.loadPreview()
            this.obs.on('SceneItemVisibilityChanged', ({ itemName, itemVisible, sceneName }) => {
                if ( sceneName === this.scene ) {
                    this.$refs[`previewItem${this.items.map(item => item.name).indexOf(itemName)}`][0].changeVisibility(itemVisible)
                }
            })
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.loadCanvas)
        },
        methods: {
            async draw() {
                this.loaded = false
                await this.loadItemList()
                this.loadCanvas()
                this.loaded = true
            },
            async loadPreview () {
                this.loaded = false
                await this.loadVideoInfo()
                await this.loadItemList()
                this.loadCanvas()
                this.loaded = true
            },
            async loadVideoInfo () {
                this.videoInfo = await this.getVideInfo()
            },
            loadCanvas () {
                this.canvas.width = this.$refs.canvas.clientWidth
                this.canvas.scale = this.canvas.width / this.videoInfo.baseWidth
                this.canvas.height = this.videoInfo.baseHeight * this.canvas.scale

                for ( const item of this.items ) {
                    item.canvas = {
                        width: item.cx * this.canvas.scale,
                        height: item.cy * this.canvas.scale,
                        x: item.properties.position.x * this.canvas.scale,
                        y: item.properties.position.y * this.canvas.scale
                    }
                }
            },
            async loadItemList () {
                this.items = []

                for ( const source of [...this.sources].reverse() ) {
                    if ( this.sourceTypesAvailable.includes(source.type) ) {
                        const settings = await this.getSourceSettings(source.name)
                        const properties = await this.getSourceProperties(this.scene, source.name)
                        if ( ['image_source', 'color_source_v2', 'text_ft2_source_v2', 'text_gdiplus_v2', 'ffmpeg_source'].includes(source.type) ) {
                            try {
                                const { img } = await this.obs.send('TakeSourceScreenshot', {
                                    sourceName: source.name,
                                    embedPictureFormat: 'png'
                                })
                                settings.img = img
                            } catch ( e ) {
                                settings.img = null
                            }
                        }
                        this.items.push({ ...source, settings, properties })
                    }
                }
            }
        }
    }
</script>
