<template>
    <div class="text-center">
        <div v-if="!obsStatus">
            <div class="row">
                <div class="col-12 text-right">
                    <button class="btn btn-primary" @click="connectObs">
                        <i class="fa fa-plug" />
                        {{ $t('Connect to') }}
                        <span v-if="obsConnect">
                            {{ obsConnect.name }} {{ $t('server') }}
                        </span>
                    </button>
                </div>
            </div>
            <div
                v-if="connectionError"
                class="alert alert-danger mt-2"
            >
                {{ connectionError }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import obsMixin from '../../mixins/obs'

export default {
    name: 'StreamProducerConnect',
    mixins: [ obsMixin ],
    props: {
        jitsiUrl: {
            type: String,
            required: false,
            default: ''
        }
    },
    data () {
        return {
            obs: null,
            scenes: [],
            settings: {},
            connectParams: {
                host: 'localhost',
                password: ''
            },
            connectionError: null
        }
    },
    computed: {
        ...mapState({
            obsConnect: state => state.obs.connect,
            obsStatus: state => state.obs.status
        })
    },
    watch: {
        obsConnect () {
            this.connectObs()
        },
        connectionError () {
            if ( this.connectionError ) {
                storeProducerLog('error', 'obs', 'Obs connection')
            }
        }
    },
    mounted () {
        if ( !this.connectParams ) {
            this.connectObs()
        }
    },
    methods: {
        ...mapActions({
            setObsConnect: 'setObsConnect',
            setObsCurrentScene: 'setObsCurrentScene',
            setObsRecordingStatus: 'setObsRecordingStatus',
            setObsScenes: 'setObsScenes',
            setObsSettings: 'setObsSettings',
            setObsStatus: 'setObsStatus',
            setObsStreamingStatus: 'setObsStreamingStatus'
        }),
        async connectObs () {
            this.connectionError = null

            const { status, obs, error:err } = await this.connectObsApi(this.obsConnect)

            if ( status ) {
                this.obs = obs

                this.obs.on('Exiting', () => {
                    this.obs = null
                    this.connectionError = 'OBS has been closed.'
                    this.setObsStatus(false)
                })
                this.obs.on('SwitchScenes', ({ sceneName }) => {
                    this.setObsCurrentScene(sceneName)
                })
                this.obs.on('StreamStarted', () => {
                    this.setObsStreamingStatus(true)
                })
                this.obs.on('StreamStopped', () => {
                    this.setObsStreamingStatus(false)
                })
                this.obs.on('RecordingStarted', () => {
                    this.setObsRecordingStatus(true)
                })
                this.obs.on('RecordingStopped', () => {
                    this.setObsRecordingStatus(false)
                })

                this.$emit('obsLoaded', this.obs)
                this.setObsStatus(true)

                await this.loadSettingsObs()
                await this.loadScenesObs()
                await this.getStreamingStatusObs()
            } else {
                this.connectionError = err.error
                this.setObsStatus(false)
            }
        },
        async loadSettingsObs () {
            const settings = await this.getStreamSettings()
            this.setObsSettings(settings)
        },
        async loadScenesObs () {
            const { currentScene, scenes } = await this.getSceneList()
            this.setObsCurrentScene(currentScene)
            await this.overwriteScenesMuted(scenes)
            await this.overwriteScenesJitsiSound(scenes, this.jitsiUrl)
            this.setObsScenes(scenes)
        },
        async getStreamingStatusObs () {
            const { streaming, recording } = await this.getStreamingStatus()
            this.setObsStreamingStatus(streaming)
            this.setObsRecordingStatus(recording)
        }
    }
}
</script>
