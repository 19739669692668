<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark bg-light">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="custom-control custom-switch mr-4">
                            <input
                                :checked="roomSourcesFilter.stream"
                                id="filterStream"
                                type="checkbox"
                                class="custom-control-input"
                                @change="toggleRoomSource('stream')"
                            />
                            <label class="custom-control-label" for="filterStream">
                                {{ $t('Stream') }}
                            </label>
                        </div>
                        <div class="custom-control custom-switch mr-4">
                            <input
                                :checked="roomSourcesFilter.webcam"
                                id="filterWebcams"
                                type="checkbox"
                                class="custom-control-input"
                                @change="toggleRoomSource('webcam')"
                            />
                            <label class="custom-control-label" for="filterWebcams">
                                {{ $t('Webcams') }}
                            </label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input
                                :checked="roomSourcesFilter.desktop"
                                id="filterDesktops"
                                type="checkbox"
                                class="custom-control-input"
                                @change="toggleRoomSource('desktop')"
                            />
                            <label class="custom-control-label" for="filterDesktops">
                                {{ $t('Desktops') }}
                            </label>
                        </div>
                    </form>
                </li>
            </ul>
        </nav>
        <div
            v-if="roomSourcesFilter.webcam"
            class="row"
        >
            <div
                v-for="stream in meetup.streams"
                :key="`source-filter-${type}-${stream.id}`"
                class="col-xs-6 col-md-4"
            >
                <div class="bg-primary text-white text-center p-1">
                    {{ stream.speaker.name }}
                </div>
                <iframe :src="`${stream.speaker.speakerStreams.webcam.view}&noaudio=1&cleanoutput=1`" class="w-100"></iframe>
            </div>
        </div>
        <div
            v-if="roomSourcesFilter.desktop"
            class="row"
        >
            <div
                v-for="stream in meetup.streams"
                :key="`source-filter-${type}-${stream.id}`"
                class="col-xs-6 col-md-4"
            >
                <div class="bg-primary text-white text-center p-1">
                    {{ stream.speaker.name }}
                </div>
                <iframe :src="`${stream.speaker.speakerStreams.desktop.view}&noaudio=1&cleanoutput=1`" class="w-100"></iframe>
            </div>
        </div>

        <template v-if="roomSourcesFilter.stream">
            <hr />
            <iframe :src="meetup.server.url" width="560" height="315" frameborder="0" allowfullscreen />
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

    export default {
        name: 'ContainerRoom',
        computed: {
            ...mapState({
                meetup: state => state.meetup,
                roomSourcesFilter: state => state.roomSourcesFilter
            })
        },
        methods: {
            ...mapActions({
                toggleRoomSource: 'toggleRoomSource'
            })
        }
    }
</script>
