<template>
    <div>
        <h1 class="text-primary text-center">{{ meetup.name }}</h1>

        <template v-if="meetup.online">
            <meetup-data :meetup="meetup" />
            <div class="row mb-4">
                <div class="col-12 col-md-8">
                    <div class="text-center">
                        <iframe
                            width="560"
                            height="315"
                            :src="youtubeIframeSrc"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            class="d-none d-md-block"
                        />
                        <iframe
                            width="325"
                            height="183"
                            :src="youtubeIframeSrc"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            class="d-block d-md-none"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <message-form :meetup="meetup" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-12">
                    <meetup-data :meetup="meetup" />
                </div>
            </div>
        </template>
        <a href="/" class="btn btn-outline-primary">
            <i class="fa fa-angle-left" />
        </a>
    </div>
</template>

<script>
import MeetupData from './MeetupData'
import MessageForm from './MessageForm'

export default {
    name: 'Meetup',
    props: {
        item: {
            type: Object,
            required: true
        },
        token: {
            type: String,
            required: true
        },
        refreshRoute: {
            type: String,
            required: true
        },
        canPublishedRoute: {
            type: String,
            required: true
        },
        publishRoute: {
            type: String,
            required: true
        }
    },
    components: { MeetupData, MessageForm },
    data () {
        return {
            meetup: { ...this.item }
        }
    },
    computed: {
        youtubeIframeSrc () {
            const split = this.meetup.stream.split('/')
            return split.length
                ? `https://www.youtube.com/embed/${split[split.length - 1]}`
                : this.meetup.stream
        }
    },
    mounted () {
        this.refresh()
    },
    methods: {
        refresh () {
            setTimeout(this.refreshRequest, 60000)
        },
        refreshRequest () {
            fetch(this.refreshRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _token: this.token
                })
            })
                .then(response => response.json())
                .then(({ status, data, redirect }) => {
                    if ( status ) {
                        this.meetup = data
                        this.refresh()
                    } else {
                        window.location.href = redirect
                    }
                })
        }
    }
}
</script>

<style scoped>
    .badge.closed {
        font-size: 2rem;
    }
</style>
