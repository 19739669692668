<template>
    <div>
        <div class="bg-light shadow p-2 border-bottom">
            <div class="row">
                <div :class="{'col-2': roomPrimaryView !== 'chat', 'col-6': roomPrimaryView === 'chat'}">
                    <button
                        class="btn btn-sm btn-link p-1"
                        @click="toggleRoomPrimaryView"
                    >
                        <i
                            :class="{'fa-caret-left': roomPrimaryView === 'jitsi', 'fa-caret-right': roomPrimaryView === 'chat'}"
                            class="fa fa-caret-left"
                        />
                    </button>
                    <template v-if="roomPrimaryView === 'chat'">
                        <div class="custom-control custom-switch d-inline-flex mr-3">
                            <input
                                id="customSwitch0"
                                v-model="filters.blockers"
                                class="custom-control-input"
                                type="checkbox"
                                @change="onChangeFilter('blockers')"
                            />
                            <label class="custom-control-label" for="customSwitch0">
                                <span
                                    class="badge badge-warning"
                                >
                                    <i class="fa fa-ban"/>
                                    {{ $t('blockers')}}
                                </span>
                            </label>
                        </div>
                        <div
                            v-if="roomPrimaryView === 'chat'"
                            class="d-inline-flex mr-2"
                        >
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa fa-search" />
                                </span>
                                </div>
                                <input
                                    v-model="filterMessages"
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('Filter messages')"
                                />
                            </div>
                        </div>
                    </template>
                </div>
                <div
                    :class="{'col-10': roomPrimaryView !== 'chat', 'col-6': roomPrimaryView === 'chat'}"
                    class="text-right"
                >
                    <template v-if="roomPrimaryView === 'chat'">
                        <div class="custom-control custom-switch d-inline-flex mr-3">
                            <input
                                id="customSwitch1"
                                v-model="filters.pending"
                                class="custom-control-input"
                                type="checkbox"
                                @change="onChangeFilter('pending')"
                            />
                            <label class="custom-control-label" for="customSwitch1">
                                <span
                                    :class="filters.pending ? 'badge-danger' : 'badge-secondary'"
                                    class="badge"
                                >
                                    {{ counters.pending }}
                                    <i class="fa fa-clock"/>
                                </span>
                            </label>
                        </div>
                        <div class="custom-control custom-switch d-inline-flex mr-3">
                            <input
                                id="customSwitch2"
                                v-model="filters.viewed"
                                class="custom-control-input"
                                type="checkbox"
                                @change="onChangeFilter('viewed')"
                            />
                            <label class="custom-control-label" for="customSwitch2">
                                <span
                                    :class="filters.viewed ? 'badge-primary' : 'badge-secondary'"
                                    class="badge"
                                >
                                    {{ counters.viewed }}
                                    <i class="fa fa-eye"/>
                                </span>
                            </label>
                        </div>
                        <div class="custom-control custom-switch d-inline-flex mr-3">
                            <input
                                id="customSwitch3"
                                v-model="filters.answered"
                                class="custom-control-input"
                                type="checkbox"
                                @change="onChangeFilter('answered')"
                            />
                            <label class="custom-control-label" for="customSwitch3">
                                <span
                                    :class="filters.answered ? 'badge-success' : 'badge-secondary'"
                                    class="badge"
                                >
                                    {{ counters.answered }}
                                    <i class="fa fa-comment-dots"/>
                                </span>
                            </label>
                        </div>
                    </template>
                    <template v-else>
                        <span
                            :class="filters.pending ? 'badge-danger' : 'badge-secondary'"
                            class="badge"
                        >
                            {{ counters.pending }}
                            <i class="fa fa-clock"/>
                        </span>
                        <span
                            :class="filters.viewed ? 'badge-primary' : 'badge-secondary'"
                            class="badge"
                        >
                            {{ counters.viewed }}
                            <i class="fa fa-eye"/>
                        </span>
                        <span
                            :class="filters.answered ? 'badge-success' : 'badge-secondary'"
                            class="badge"
                        >
                            {{ counters.answered }}
                            <i class="fa fa-comment-dots"/>
                        </span>
                    </template>
                </div>
            </div>
        </div>
        <div class="bg-light shadow" style="height: 75vh">
            <div id="questionsContainer" ref="questionsContainer" class="h-100 p-2">
                <div
                    v-if="messages.length"
                    class="list-group"
                >
                    <question
                        v-for="message in messagesFiltered"
                        :key="`message-${message.id}`"
                        :csrf_token="csrf_token"
                        :message="message"
                        :message-destroy-route="messageDestroyRoute.replace(':message', message.id)"
                        :message-update-route="messageUpdateRoute.replace(':message', message.id)"
                        :writer-update-route="writerUpdateRoute.replace(':writer', message.writer.id)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Question from './Question'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'Questions',
    props: {
        messagesRoute: {
            type: String,
            required: false,
            default: null
        },
        messageUpdateRoute: {
            type: String,
            required: false,
            default: null
        },
        messageDestroyRoute: {
            type: String,
            required: false,
            default: null
        },
        writerUpdateRoute: {
            type: String,
            required: false,
            default: null
        },
        csrf_token: {
            type: String,
            required: true
        }
    },
    components: { Question },
    data() {
        return {
            messages: [],
            filters: {
                pending: true,
                viewed: true,
                answered: true,
                blockers: true
            },
            filterMessages: ''
        }
    },
    computed: {
        ...mapState({
            roomPrimaryView: state => state.roomPrimaryView,
            roomMessageFilters: state => state.roomMessageFilters
        }),
        counters() {
            const counters = {}

            for ( status of [ 'pending', 'viewed', 'answered' ] ) {
                counters[ status ] = this.messages.filter(message => message.status === status).length
            }

            return counters
        },
        messagesFiltered() {
            return this.messages.filter(message => {
                return (
                    ( this.filters.pending && message.status === 'pending' ) ||
                    ( this.filters.viewed && message.status === 'viewed' ) ||
                    ( this.filters.answered && message.status === 'answered' )
                ) && (
                    this.filters.blockers || !message.writer.blocker
                ) && (
                    message.content.includes(this.filterMessages) ||
                    message.writer.name.includes(this.filterMessages) ||
                    message.writer.email.includes(this.filterMessages)
                )
            })
        }
    },
    mounted() {
        this.filters = { ...this.roomMessageFilters }
        this.getMessagesRequest()
    },
    methods: {
        ...mapActions({
            toggleRoomPrimaryView: 'toggleRoomPrimaryView',
            toggleRoomMessageFilter: 'toggleRoomMessageFilter'
        }),
        onChangeFilter(filter) {
            this.toggleRoomMessageFilter(filter)
        },
        async getMessagesRequest() {
            try {
                fetch(this.messagesRoute, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.csrf_token
                    })
                })
                    .then(response => response.json())
                    .then(({ status, data }) => {
                        if ( status ) {
                            this.messages = data
                        }

                        setTimeout(this.getMessagesRequest, 3000)
                    })
            } catch ( e ) {
            }
        }
    }
}
</script>

<style scoped>
#questionsContainer {
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
}

.questions {
    bottom: 0
}
</style>
