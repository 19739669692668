export default {
    state: {
        meetups: null,
        sourceCollapse: false,
        showPreview: false,
        roomSourcesFilter: {
            desktop: false,
            webcam: true,
            stream: false
        },
        sceneSourcesFilter: {
            cameras: true,
            images: true,
            others: false,
            videos: true,
            texts: true,
        },
        currentView: {
            type: null,
            key: null
        },
        obs: {
            connect: null,
            currentScene: '',
            recordingStatus: false,
            settings: null,
            scenes: [],
            status: false,
            streamingStatus: false
        },
        guest: {
            name: '',
            email: ''
        },
        roomPrimaryView: 'jitsi',
        roomSecondaryView: 'chat',
        roomWriterInMeetup: true,
        roomMessageFilters: {
            pending: true,
            viewed: true,
            answered: true,
            blockers: true
        },
        pings: []
    },
    mutations: {
        SET_MEETUP (state, meetup) {
            state.meetup = meetup
        },
        SET_CURRENT_VIEW (state, { type, key }) {
            state.currentView = { type, key }
        },
        SET_GUEST (state, { name, email }) {
            state.guest = { name, email }
        },
        SET_OBS_CONNECT (state, { host, port, password, name }) {
            state.obs.connect = { host, port, password, name }
        },
        SET_OBS_CURRENT_SCENE (state, currentScene) {
            state.obs.currentScene = currentScene
        },
        SET_OBS_RECORDING_STATUS (state, recordingStatus) {
            state.obs.recordingStatus = recordingStatus
        },
        SET_OBS_SCENES (state, scenes) {
            state.obs.scenes = scenes
        },
        SET_OBS_SETTINGS (state, settings) {
            state.obs.settings = settings
        },
        SET_OBS_STATUS (state, status) {
            state.obs.status = status
        },
        SET_OBS_STREAMING_STATUS (state, streamingStatus) {
            state.obs.streamingStatus = streamingStatus
        },
        SET_PINGS (state, pings) {
            state.pings = pings
        },
        SET_SOURCE_COLLAPSE (state, collapse) {
            state.sourceCollapse = collapse
        },
        TOGGLE_SHOW_PREVIEW (state) {
            state.showPreview = !state.showPreview
        },
        TOGGLE_ROOM_SOURCE (state, source) {
            state.roomSourcesFilter[source] = !state.roomSourcesFilter[source]
        },
        TOGGLE_SCENE_SOURCE_FILTER (state, type) {
            state.sceneSourcesFilter[type] = !state.sceneSourcesFilter[type]
        },
        TOGGLE_ROOM_PRIMARY_ROOM (state) {
            state.roomPrimaryView = state.roomPrimaryView === 'jitsi' ? 'chat' : 'jitsi'
        },
        TOGGLE_ROOM_SECONDARY_ROOM (state) {
            state.roomSecondaryView = state.roomSecondaryView === 'chat' ? 'writers' : 'chat'
        },
        TOGGLE_ROOM_WRITER_IN_MEETUP (state) {
            state.roomWriterInMeetup = !state.roomWriterInMeetup
        },
        TOGGLE_ROOM_MESSAGE_FILTERS (state, filter) {
            state.roomMessageFilters[filter] = !state.roomMessageFilters[filter]
        },
    },
    actions: {
        setCurrentView ({ commit }, payload) {
            commit('SET_CURRENT_VIEW', payload)
        },
        setGuest ({ commit }, payload) {
            commit('SET_GUEST', payload)
        },
        setMeetup({ commit }, payload) {
            commit('SET_MEETUP', payload)
        },
        setObsConnect ({ commit }, payload) {
            commit('SET_OBS_CONNECT', payload)
        },
        setObsCurrentScene({ commit }, payload) {
            commit('SET_OBS_CURRENT_SCENE', payload)
        },
        setObsRecordingStatus({ commit }, payload) {
            commit('SET_OBS_RECORDING_STATUS', payload)
        },
        setObsScenes({ commit }, payload) {
            commit('SET_OBS_SCENES', payload)
        },
        setObsSettings({ commit }, payload) {
            commit('SET_OBS_SETTINGS', payload)
        },
        setObsStatus({ commit }, payload) {
            commit('SET_OBS_STATUS', payload)
        },
        setObsStreamingStatus({ commit }, payload) {
            commit('SET_OBS_STREAMING_STATUS', payload)
        },
        setPings({ commit }, payload) {
            commit('SET_PINGS', payload)
        },
        setSourceCollapse ({ commit }, collapse) {
            commit('SET_SOURCE_COLLAPSE', collapse)
        },
        toggleShowPreview ({ commit }) {
            commit('TOGGLE_SHOW_PREVIEW')
        },
        toggleRoomSource ({ commit }, source) {
            commit('TOGGLE_ROOM_SOURCE', source)
        },
        toggleSceneSourceFilter ({ commit }, payload) {
            commit('TOGGLE_SCENE_SOURCE_FILTER', payload)
        },
        toggleRoomPrimaryView ({ commit }) {
            commit('TOGGLE_ROOM_PRIMARY_ROOM')
        },
        toggleRoomSecondaryView ({ commit }) {
            commit('TOGGLE_ROOM_SECONDARY_ROOM')
        },
        toggleWriterInMeetup ({ commit }) {
            commit('TOGGLE_ROOM_WRITER_IN_MEETUP')
        },
        toggleRoomMessageFilter ({ commit }, payload) {
            commit('TOGGLE_ROOM_MESSAGE_FILTERS', payload)
        }
    }
}
