<template>
    <div
        v-if="properties && !isJitsi"
        class="row"
    >
        <div class="col-6">
            {{ source.name }}
            <span class="badge badge-secondary">
                <i
                    v-if="type.icon"
                    :class="`fa ${type.icon}`"
                />
                {{ $t(type.label) }}
            </span>
            <template v-if="isStream">
                <div
                    v-if="currentVideoStream"
                    class="badge"
                    :class="{'badge-success': ping, 'badge-danger': !ping}"
                >
                    <i
                        class="fa"
                        :class="{'fa-camera': currentVideoStream.type === 'webcam', 'fa-desktop': currentVideoStream.type === 'desktop'}"
                    />
                    {{ currentVideoStream.speaker.name }}
                    <span v-if="currentVideoStream.speaker.is_presenter"> - {{ $t('Presenter') }}</span>
                </div>
                <div
                    v-else-if="settings && settings.url"
                    class="badge badge-danger"
                >
                    {{ $t('Speaker without meetup') }}
                </div>
                <div
                    v-else
                    class="badge badge-warning"
                >
                    {{ $t('Empty') }}
                </div>
            </template>
            <br>
            <small class="badge badge-light">{{ properties.type }}</small>
        </div>
        <div class="col-6 text-right">
            <div class="btn-group btn-group-sm">
                <button
                    v-if="isEditable"
                    class="btn btn-primary"
                    @click="handleToggleEdit"
                >
                    <i
                        class="fa"
                        :class="{'fa-undo': edit, 'fa-pencil-alt': !edit}"
                    />
                </button>
                <button
                    class="btn btn-light"
                    @click="handleToggleShow"
                >
                    <label-on-off
                        :status="properties.visible"
                        icon-on="fa-eye"
                        icon-off="fa-eye-slash"
                    />
                </button>
            </div>
        </div>
        <div
            v-if="edit"
            class="col-12 p-4"
        >
            <scene-source-edit
                :source="source"
                :streams="videoStreams"
                :current-stream="currentVideoStream"
                :settings="settings"
                :codecs="codecs"
                :scales="scales"
                :bit-rates="bitRates"
                @onApply="handleApplyStream"
            />
        </div>
        <div class="col-12">
            <hr />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import obsMixin from '../../mixins/obs'
    import LabelOnOff from './LabelOnOff'
    import SceneSourceEdit from './SceneSourceEdit'

    export default {
        name: 'SceneSource',
        props: {
            obs: {
                type: Object,
                required: true
            },
            scene: {
                type: String,
                required: true
            },
            source: {
                type: Object,
                required: true
            }
        },
        components: { SceneSourceEdit, LabelOnOff },
        mixins: [ obsMixin ],
        data () {
            return {
                settings: null,
                properties: null,
                edit: false,
                isMuted: false
            }
        },
        computed: {
            ...mapState({
                meetup: state => state.meetup,
                pings: state => state.pings
            }),
            isStream () {
                return this.source.type === 'browser_source'
            },
            isEditable () {
                const editableSources = [
                    'browser_source',
                    'text_ft2_source_v2',
                    'text_gdiplus_v2'
                ]
                return editableSources.includes(this.source.type)
            },
            isJitsi () {
                return this.getSourceIsJitsi(this.source)
            },
            hasSound () {
                return !['image_source', 'color_source_v2', 'text_ft2_source_v2', 'text_gdiplus_v2'].includes(this.source.type)
            },
            currentVideoStream () {
                let currentVideoStream = null

                if ( this.settings && this.settings.url ) {
                    currentVideoStream = this.videoStreams.find(item => this.settings.url.includes(item.stream))
                }

                return currentVideoStream ?? null
            },
            codecs () {
                return ['vp8', 'h264', 'vp9']
            },
            scales () {
                const scales = []

                for ( let i = 10; i <= 100; i = i + 5 ) {
                    scales.push(i)
                }

                return scales
            },
            bitRates () {
                const bitRates = []

                for ( let i = 3; i <= 45; i++ ) {
                    bitRates.push(i * 100)
                }

                return bitRates
            },
            ping () {
                if ( this.currentVideoStream ) {
                    const filter = this.pings.filter(ping => ping.user === this.currentVideoStream.speaker.id)

                    if ( filter.length ) {
                        return this.currentVideoStream.type === 'webcam' ? filter[0].webcam : filter[0].desktop
                    }
                }

                return false
            },
            type () {
                switch (this.source.type) {
                    case 'browser_source':
                        return {
                            icon: 'fa-video',
                            label: 'streaming'
                        }
                    case 'text_gdiplus_v2':
                    case 'text_ft2_source_v2':
                        return {
                            icon: 'fa-edit',
                            label: 'text'
                        }
                    case 'color_source_v2':
                    case 'image_source':
                        return {
                            icon: 'fa-image',
                            label: 'image'
                        }
                    case 'ffmpeg_source':
                        return {
                            icon: 'fa-play',
                            label: 'video'
                        }
                    default:
                        return {
                            icon: null,
                            label: this.source.type
                        }
                }
            },
            videoStreams () {
                const videoStreams = []

                for ( const stream of this.meetup.participants_streams ) {
                    const { id, name, email, is_presenter, webcam, desktop } = stream
                    videoStreams.push({
                        speaker: { id, name, email, is_presenter },
                        stream: `${webcam}`,
                        type: 'webcam'
                    })
                    videoStreams.push({
                        speaker: { id, name, email, is_presenter },
                        stream: `${desktop}`,
                        type: 'desktop'
                    })
                }

                return videoStreams
            }
        },
        mounted () {
            this.loadSettings()
            this.loadProperties()
            this.loadIsMuted()
        },
        methods: {
            async loadSettings () {
                this.settings = await this.getSourceSettings(this.source.name)
            },
            async loadProperties () {
                this.properties = await this.getSourceProperties(this.scene, this.source.name)
            },
            async loadIsMuted () {
                this.isMuted = await this.getMute(this.source.name)
            },
            handleApplyStream ( updatedSettings ) {
                this.setSourceSettingsObs(updatedSettings)
                this.loadSettings()
                this.handleToggleEdit()
                this.loadSettings()
                this.$emit('sourceUpdated')
            },
            handleToggleEdit () {
                this.edit = !this.edit
            },
            async handleToggleShow () {
                this.properties.visible = !this.properties.visible
                await this.setSceneItemPropertiesObs({
                    visible: this.properties.visible
                })
                await this.loadProperties()
                this.$emit('sourceUpdated')
            },
            async handleToggleSound () {
                await this.toggleMute(this.source.name)
                await this.loadIsMuted()
                this.$emit('sourceUpdated')
            },
            setSourceSettingsObs ( settingsToUpdate ) {
                this.setSourceSettings(this.source.name, this.source.type, { ...this.settings, ...settingsToUpdate })
            },
            setSceneItemPropertiesObs ( propertiesToUpdate ) {
                this.setSceneItemProperties(this.scene, this.properties, propertiesToUpdate)
            }
        }
    }
</script>
