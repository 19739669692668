<template>
    <div>
        <div
            v-for="(meetup, index) in meetups"
            :key="`meetup-${meetup.id}`"
            class="meetup row mb-4"
        >
            <div class="col-12 col-md-10">
                <div class="text-primary">
                    {{ meetup.date }}
                </div>
                <h4>
                    {{ meetup.name }}
                </h4>
                <p
                    v-if="meetup.description"
                    v-html="meetup.description"
                    class="text-muted"
                />
                <div v-if="meetup.streams.length">
                    <span
                        v-for="(stream, index) in meetup.streams"
                        :key="`meetup-${meetup.id}-stream-${stream.id}`"
                    >
                        {{ index ? `, ${stream.speaker.name}` : stream.speaker.name }}
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-2 text-center text-md-right mt-3">
                <a
                    v-if="meetup.campus_url || meetup.stream"
                    :href="meetup.campus_url ? meetup.campus_url : meetup.link"
                    :target="meetup.campus_url ? '_target' : ''"
                    class="btn btn-outline-primary btn-fede-info"
                >
                    {{ $t('VER') }}
                </a>
                <button
                    v-else
                    class="btn btn-outline-primary btn-fede-info"
                    disabled
                >
                    {{ $t('VER') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Meetups',
    props: {
        items: {
            type: Array,
            required: false,
            default: Array
        },
        refreshRoute: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            meetups: [...this.items]
        }
    },
    mounted () {
        this.refresh()
    },
    methods: {
        refresh () {
            setTimeout(this.refreshRequest, 60000)
        },
        refreshRequest () {
            try {
                fetch(this.refreshRoute, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.token
                    })
                })
                    .then(response => response.json())
                    .then(({ status, data }) => {
                        if ( status ) {
                            this.meetups = data
                            this.refresh()
                        }
                    })
            } catch (e){}
        }
    }
}
</script>
