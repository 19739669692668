<template>
    <div v-if="loaded" class="text-center">
        <template v-if="obs">
            <div>
                <div class="badge badge-success">{{ $t('Scene') }}: {{ currentScene }}</div>
                <div
                    class="badge"
                    :class="{'badge-success': streamingStatus, 'badge-danger': !streamingStatus}"
                >
                    <i
                        class="fa"
                        :class="{'fa-play': streamingStatus, 'fa-pause': !streamingStatus}"
                    />
                    {{ $t('Streaming') }}
                </div>
            </div>
            <div v-if="sources.length" class="mt-2">
                <div
                    v-for="source in sources"
                    :key="`source-${source.id}`"
                    class="btn btn-sm btn-primary mr-1 ml-1"
                    @click="handleToggleShow(source)"
                >
                    <i
                        class="fa"
                        :class="{'fa-eye': source.properties.visible, 'fa-eye-slash': !source.properties.visible}"
                    />
                    {{ source.name }} ({{ $t('Scene') }}: {{ source.scene }})
                </div>
            </div>
        </template>
        <template v-else>
            <div class="alert alert-danger">{{ $t('Error connection') }}</div>
        </template>
    </div>
</template>

<script>
    import obsMixin from '../../mixins/obs'

	export default {
		name: 'StreamSpeaker',
        mixins: [ obsMixin ],
        data () {
		    return {
		        loaded: false,
		        obs: null,
                currentScene: null,
                sources: [],
                scenes: [],
                settings: null,
                streamingStatus: false,
                urlViewStream: urlViewStream
            }
        },
        mounted () {
		    if ( this.urlViewStream ) {
                this.initialize()
            }
        },
        methods: {
		    async initialize () {
		        this.loaded = false
                const { status, obs } = await this.connectObsApi(obsCredentials.host, obsCredentials.password)

                if ( status ) {
                    this.obs = obs

                    this.obs.on('Exiting', () => {
                        this.obs = null
                    })
                    this.obs.on('SwitchScenes', ({ sceneName }) => {
                        this.currentScene = sceneName
                    })
                    this.obs.on('StreamStarted', () => {
                        this.streamingStatus = true
                    })
                    this.obs.on('StreamStopped', () => {
                        this.streamingStatus = false
                    })

                    await this.loadSettingsObs()
                    await this.loadScenesObs()
                    await this.loadStreamingStatusObs()
                }
                this.loaded = true
            },
            async loadSettingsObs () {
                this.settings = await this.getStreamSettings()
            },
            async loadScenesObs () {
                const { currentScene, scenes } = await this.getSceneList()
                this.currentScene = currentScene
                this.scenes = scenes
                await this.loadMySources()

                setTimeout(() => {
                    this.loadScenesObs()
                }, 30000)
            },
            async loadMySources () {
		        const sources = []

		        for ( const scene of this.scenes ) {
		            for ( const source of scene.sources ) {
		                if ( source.type === 'browser_source' ) {
                            const { url } = await this.getSourceSettings(source.name)
                            if ( url === this.urlViewStream ) {
                                const properties = await this.getSourceProperties(scene.name, source.name)

                                if ( properties.status === 'ok' ) {
                                    sources.push({
                                        ...source,
                                        scene: scene.name,
                                        properties
                                    })
                                }
                            }
                        }
                    }
                }

		        this.sources = sources
            },
            async loadStreamingStatusObs () {
                this.streamingStatus = await this.getStreamingStatus()
            },
            handleToggleShow ( source ) {
                this.setSceneItemProperties(source.scene, source.properties, { visible: !source.properties.visible})
                source.properties.visible = !source.properties.visible
            }
        }
	}
</script>
