<template>
    <div>
        <div class="list-group">
            <template v-for="(scene, index) in scenes">
                <div
                    v-if="!scene.name.includes('00. ')"
                    :key="`nav-scene-${index}`"
                    class="list-group-item p-2"
                    :class="{'active': currentView.type === 'scene' && currentView.key === scene.name}"
                >
                    <label-on-off
                        :status="scene.name === obsCurrentScene"
                        icon-on="fa-circle"
                        icon-off="fa-circle"
                    />
                    <span
                        class="btn btn-link text-left"
                        :class="{'text-white': currentView.type === 'scene' && currentView.key === scene.name}"
                        @click="handleSetCurrentView('scene', scene.name)"
                    >
                        {{ scene.name }}
                    </span>
                </div>
            </template>
        </div>
        <div class="list-group mt-2">
            <div
                class="list-group-item p-2"
                :class="{'active': currentView.type === 'room'}"
            >
                <i
                    class="fa fa-home ml-1"
                    :class="{'text-white': currentView.type === 'room', 'text-primary': currentView.type !== 'room'}"
                />
                <a
                    :href="$parent.roomRoute"
                    class="btn btn-link text-left text-primary"
                    target="_blank"
                >
                    {{ $t('Room chat') }}
                </a>
            </div>
            <div
                class="list-group-item p-2"
                :class="{'active': currentView.type === 'room'}"
            >
                <i
                    class="fa fa-eye ml-1"
                    :class="{'text-white': currentView.type === 'room', 'text-primary': currentView.type !== 'room'}"
                />
                <a
                    :href="$parent.controlRoute"
                    class="btn btn-link text-left text-primary"
                    target="_blank"
                >
                    {{ $t('Room control') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import LabelOnOff from './LabelOnOff'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'SideBar',
    components: { LabelOnOff },
    computed: {
        ...mapState({
            currentView: state => state.currentView,
            obsCurrentScene: state => state.obs.currentScene,
            scenes: state => state.obs.scenes
        })
    },
    methods: {
        ...mapActions({
            setCurrentView: 'setCurrentView'
        }),
        handleSetCurrentView ( type, key ) {
            this.setCurrentView({ type, key })
        }
    }
}
</script>
