<template>
    <div v-if="loaded" class="card rounded shadow-sm p-2">
        <div v-if="canPublished" class="p-2">
            <h4>{{ $t('Do you want to ask a question?') }}</h4>
            <hr>
            <div class="form-group">
                <label for="email">{{ $t('Email') }}: *</label>
                <input
                    v-model="email"
                    id="email"
                    type="text"
                    :disabled="!!guest.email"
                    class="form-control"
                    :class="{'is-invalid': errors.email}"
                />
                <span
                    v-if="errors.email"
                    class="invalid-feedback bg-danger text-white rounded p-2" role="alert"
                >
                    <strong>{{ errors.email[0] }}</strong>
                </span>
            </div>
            <div class="form-group">
                <label for="name">{{ $t('Name') }}: *</label>
                <input
                    v-model="name"
                    id="name"
                    type="text"
                    :disabled="!!guest.name"
                    class="form-control"
                    :class="{'is-invalid': errors.name}"
                />
                <span
                    v-if="errors.name"
                    class="invalid-feedback bg-danger text-white rounded p-2" role="alert"
                >
                    <strong>{{ errors.name[0] }}</strong>
                </span>
            </div>
            <div class="form-group">
                <label for="message">{{ $t('Message') }}: *</label>
                <textarea
                    v-model="message"
                    id="message"
                    class="form-control"
                    :class="{'is-invalid': errors.message}"
                />
                <span
                    v-if="errors.message"
                    class="invalid-feedback bg-danger text-white rounded p-2" role="alert"
                >
                    <strong>{{ errors.message[0] }}</strong>
                </span>
            </div>
            <div class="text-right">
                <button
                    class="btn btn-sm btn-outline-primary"
                    @click="publish"
                >
                    <i class="fa fa-paper-plane" />
                    {{ $t('Send') }}
                </button>
            </div>
            <div
                v-if="messageSent"
                class="alert alert-success mt-2"
            >
                {{ $t('Message has been sent') }}
            </div>
        </div>
        <div
            v-else
            class="alert alert-warning"
        >
            {{ $t('Sorry, you has been banned')}}
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'MessageForm',
    props: {
        meetup: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loaded: false,
            canPublished: false,
            messageSent: false,
            email: '',
            name: '',
            message: '',
            errors: []
        }
    },
    computed: {
        ...mapState({
            guest: state => state.guest
        })
    },
    watch: {
        messageSent () {
            if ( this.messageSent ) {
                setTimeout(() => {
                    this.messageSent = false
                }, 5000)
            }
        }
    },
    mounted () {
        this.initialize()
    },
    methods: {
        ...mapActions({
            setGuest: 'setGuest'
        }),
        async initialize () {
            this.loaded = false
            this.email = this.guest.email
            this.name = this.guest.name

            await this.checkCanPublished()
        },
        async checkCanPublished () {
            this.canPublished = await this.getCanPublished()
            this.loaded = true
        },
        async getCanPublished () {
            if ( !this.email.length ) {
                return true
            }

            const { ok } = await this.getCanPublishedRequest()
            return ok
        },
        async getCanPublishedRequest () {
            return await fetch(this.$parent.canPublishedRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _token: this.$parent.token,
                    email: this.email
                })
            })
        },
        async publish () {
            this.errors = []
            this.canPublished = true

            const response = await this.publishRequest()
            const { ok, status } = response
            const { errors, redirect } = await response.json()

            if ( ok ) {
                this.messageSent = true
                this.message = ''
                this.setGuest({
                    name: this.name,
                    email: this.email
                })
            } else {
                if ( status === 404 ) { // is closed
                    window.location.href = redirect
                } else if ( status === 400 ) { // validation form
                    this.errors = errors
                } else if ( status === 401 ) { // is blocked
                    this.canPublished = false
                }
            }
        },
        async publishRequest () {
            return await fetch(this.$parent.publishRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _token: this.$parent.token,
                    email: this.email,
                    name: this.name,
                    message: this.message
                })
            })
        }
    }
}
</script>
