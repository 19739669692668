<template>
    <div
        class="jitsi"
        :class="{'jitsi-obs': isObs}"
    >
        <template v-if="isObs">
            <vue-jitsi-meet
                ref="jitsiRef"
                domain="meet2.labelgrup.com"
                :options="jitsiOptions"
                class="jitsi"
                :class="{'jitsi-obs': isObs}"
            />
        </template>
        <template v-else>
            <vue-confirm-dialog />
            <h3 class="text-primary text-center">{{ meetup.name }}</h3>
            <div class="row mb-2">
                <div class="col-6">
                    <template v-if="advanceView">
                        <a :href="controlRoute" class="btn btn-primary btn-sm my-2 my-sm-0" target="_blank">
                            <i class="fa fa-eye" />
                            {{ $t('Room control')}}
                        </a>
                        <div class="btn-group btn-group-sm" role="group">
                            <button
                                id="btnGroupSecondaryView"
                                class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i
                                    class="fa"
                                    :class="{'fa-comments': roomSecondaryView === 'chat', 'fa-users': roomSecondaryView === 'writers'}"
                                />
                                {{ $t(roomSecondaryView === 'chat' ? 'Chat' : 'Writers') }}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupSecondaryView">
                                <button
                                    class="dropdown-item"
                                    @click="toggleRoomSecondaryView"
                                >
                                    <i
                                        class="fa"
                                        :class="{'fa-comments': roomSecondaryView === 'writers', 'fa-users': roomSecondaryView === 'chat'}"
                                    />
                                    {{ $t(roomSecondaryView === 'chat' ? 'Writers' : 'Chat') }}
                                </button>
                            </div>
                            <div
                                v-if="streamAvailable"
                                class="custom-control custom-switch ml-2"
                            >
                                <input
                                    :checked="showStream"
                                    id="filterStream"
                                    type="checkbox"
                                    class="custom-control-input"
                                    @change="toggleRoomSource('stream')"
                                />
                                <label class="custom-control-label" for="filterStream">
                                    {{ $t('Stream') }}
                                </label>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-if="auth.speakerStreams" class="col-6 text-right">
                    <a :href="auth.speakerStreams.desktop.share" class="btn btn-primary btn-sm my-2 my-sm-0" target="_blank">
                        <i class="fa fa-desktop" />
                        {{ $t('Share desktop')}}
                    </a>
                </div>
            </div>
            <div class="row">
                <div
                    class="col-12"
                    :class="{
                    'col-md-12': !advanceView,
                    'col-md-9': advanceView && roomPrimaryView === 'jitsi',
                    'col-md-3': advanceView && roomPrimaryView === 'chat'
                }"
                    :style="isObs ? 'height: 100vh' : 'height: 75vh'"
                >
                    <div
                        v-if="!auth.is_producer && meetup.producer.phone"
                        class="alert alert-info mb-4"
                    >
                        {{ $t('If you have any technical problem you can use chat to talk with producer or call to') }} <strong>{{ meetup.producer.phone }}</strong>.
                    </div>
                    <div v-if="streamAvailable && showStreamStore" class="text-center">
                        <hr />
                        <iframe :src="meetup.server.url" width="560" height="315" frameborder="0" allowfullscreen />
                        <div v-if="showStreamAlert" class="alert alert-warning">
                            <i class="fa fa-exclamation-triangle" />
                            {{ $t('Remember to mute the stream so that there are no sound problems in the broadcast.') }}
                            <button
                                type="button"
                                class="close"
                                data-dismiss="alert"
                                aria-label="Close"
                                @click="showStreamAlert = false"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <hr />
                    </div>
                    <div
                        v-if="userPings.length"
                        class="row"
                    >
                        <div
                            v-for="(ping, index) in userPings"
                            :key="`ping-${index}`"
                            class="col-6 col-md-4 mb-2"
                        >
                            <div class="bg-primary text-white text-center p-1">
                                <div>
                                    <i
                                        v-if="ping.is_presenter"
                                        class="fa fa-star"
                                    />
                                    {{ ping.name }}
                                </div>
                                <div>
                                    <label-on-off
                                        :status="ping.webcam"
                                        icon-on="fa-camera"
                                        icon-off="fa-camera"
                                    />
                                    <label-on-off
                                        :status="ping.desktop"
                                        icon-on="fa-desktop"
                                        icon-off="fa-desktop"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <vue-jitsi-meet
                        ref="jitsiRef"
                        domain="meet2.labelgrup.com"
                        :options="jitsiOptions"
                        class="jitsi"
                    />
                </div>
                <div
                    v-if="advanceView"
                    class="col-12"
                    :class="{'col-md-9': roomPrimaryView === 'chat', 'col-md-3': roomPrimaryView === 'jitsi'}"
                >
                    <questions
                        v-if="roomSecondaryView === 'chat'"
                        :messages-route="messagesRoute"
                        :message-update-route="messageUpdateRoute"
                        :message-destroy-route="messageDestroyRoute"
                        :writer-update-route="writerUpdateRoute"
                        :csrf_token="csrf_token"
                    />
                    <writers
                        v-else-if="roomSecondaryView === 'writers'"
                        :meetup="meetup"
                        :writers-request="writersRequest"
                        :writer-update-route="writerUpdateRoute"
                        :csrf_token="csrf_token"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { JitsiMeet } from '@mycure/vue-jitsi-meet'
    import Questions from './Questions'
    import Writers from './Writers'
    import LabelOnOff from '../StreamProducer/LabelOnOff'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'JitsiMeet',
        props: {
            meetup: {
                type: Object,
                required: true
            },
            auth: {
                type: Object,
                required: true
            },
            controlRoute: {
                type: String,
                required: true
            },
            updateJitsiRoute: {
                type: String,
                required: false,
                default: null
            },
            checkJitsiRoute: {
                type: String,
                required: false,
                default: null
            },
            messagesRoute: {
                type: String,
                required: false,
                default: null
            },
            messageUpdateRoute: {
                type: String,
                required: false,
                default: null
            },
            messageDestroyRoute: {
                type: String,
                required: false,
                default: null
            },
            writersRequest: {
                type: String,
                required: false,
                default: null
            },
            writerUpdateRoute: {
                type: String,
                required: false,
                default: null
            },
            pingsRoute: {
                type: String,
                required: true
            },
            csrf_token: {
                type: String,
                required: false,
                default: null
            },
            isObs: {
                type: Boolean,
                required: false,
                default: false
            },
            userFocus: {
                type: Object,
                required: false,
                default: null
            }
        },
        components: {
            Questions,
            Writers,
            LabelOnOff,
            VueJitsiMeet: JitsiMeet
        },
        data () {
            return {
                jitsi: null,
                pings: [],
                showStream: false,
                showStreamAlert: true,
                isTileView: true,
                participants: []
            }
        },
        computed: {
            ...mapState({
                roomPrimaryView: state => state.roomPrimaryView,
                roomSecondaryView: state => state.roomSecondaryView,
                showStreamStore: state => state.roomSourcesFilter.stream
            }),
            advanceView () {
                return this.auth.is_admin || this.auth.is_producer || this.auth.is_presenter
            },
            jitsiOptions () {
                return {
                    roomName: this.meetup.jitsi_room,
                    width: '100%',
                    height: '100%',
                    passwordRequired: true,
                    noSSL: false,
                    userInfo: {
                        email: this.auth.email,
                        displayName: this.auth.name,
                    },
                    configOverwrite: {
                        host: {
                            domain: 'meet2.labelgrup.com'
                        },
                        websocket: 'wss://meet2.labelgrup.com/xmpp-websocket',
                        //liveStreamingEnabled: true,
                        //useTurnUdp: true,
                        prejoinPageEnabled: false,
                        disableRecordAudioNotification: true,
                        apiLogLevels: ['error', 'warn']
                    },
                    interfaceConfigOverwrite: {
                        APP_NAME: 'Fedefarma Meeting',
                        DEFAULT_LOGO_URL: '',
                        DEFAULT_WELCOME_PAGE_LOGO_URL: '',
                        DISABLE_DOMINANT_SPEAKER_INDICATOR: false,
                        DISABLE_FOCUS_INDICATOR: true,
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                        DISABLE_RINGING: true,
                        HIDE_DEEP_LINKING_LOGO: true,
                        HIDE_INVITE_MORE_HEADER: true,
                        NATIVE_APP_NAME: 'Fedefarma Meeting',
                        JITSI_WATERMARK_LINK: '',
                        SHOW_BRAND_WATERMARK: false,
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_POWERED_BY: false,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        SHOW_CHROME_EXTENSION_BANNER: false,
                        TOOLBAR_BUTTONS: [
                            'microphone',
                            'camera',
                            //'closedcaptions',
                            //'desktop',
                            //'embedmeeting',
                            'fullscreen',
                            //'fodeviceselection',
                            //'hangup',
                            //'profile',
                            'chat',
                            //'recording',
                            //'livestreaming',
                            //'etherpad',
                            //'sharedvideo',
                            //'settings',
                            //'raisehand',
                            //'videoquality',
                            'filmstrip',
                            //'invite',
                            //'feedback',
                            //'stats',
                            //'shortcuts',
                            //'tileview',
                            //'videobackgroundblur',
                            //'download',
                            //'help',
                            //'mute-everyone',
                            //'security'
                        ],
                        SETTINGS_SECTIONS: [ 'devices', 'language', 'moderator', 'profile', 'calendar' ],
                        FILM_STRIP_MAX_HEIGHT: 50
                    },
                    onload: this.onIFrameLoad
                };
            },
            streamAvailable () {
                return this.auth.is_admin || this.auth.is_producer || this.auth.is_presenter
            },
            userPings () {
                const users = []

                for ( const ping of this.pings ) {
                    const user = ping.user === this.meetup.presenter.id
                        ? this.meetup.presenter
                        : this.meetup.streams.find(stream => stream.speaker.id === ping.user)

                    if ( user ) {
                        users.push({
                            ...ping,
                            name: user.speaker ? user.speaker.name : user.name
                        })
                    }
                }

                return users
            }
        },
        mounted () {
            this.showStream = this.showStreamStore

            this.checkPings()

            if ( this.checkJitsiRoute ) {
                this.checkJitsi()
            }
        },
        methods: {
            ...mapActions({
                toggleRoomSecondaryView: 'toggleRoomSecondaryView',
                toggleRoomSource: 'toggleRoomSource'
            }),
            checkJitsi () {
                setTimeout(this.checkJitsiRequest, 30000)
            },
            checkJitsiRequest () {
                fetch(this.checkJitsiRoute, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.csrf_token,
                        room: this.meetup.jitsi_room
                    })
                })
                    .then(res => res.json())
                    .then(({ result }) => {
                        if ( result ) {
                            this.checkJitsi()
                        } else {
                            location.reload()
                        }
                    })
            },
            checkPings () {
                fetch(this.pingsRoute, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.csrf_token
                    })
                })
                    .then(response => response.json())
                    .then(({ status, data }) => {
                        if ( status ) {
                            this.pings = data
                        }
                    })

                setTimeout(this.checkPings, 10000)
            },
            async onIFrameLoad ( e ) {
                this.jitsi = this.$refs.jitsiRef
            }
        }
    }
</script>

<style scoped>
    .jitsi {
        height: 75vh;
    }

    .jitsi-obs {
        height: 100vh;
    }
</style>
