<template>
    <div
        class="preview-source"
        :class="{'is-hide': !visibility}"
        :style="style"
        :title="item.name"
    >
        <template v-if="item.type === 'browser_source'">
            <div
                class="preview-stream text-dark text-center h-100 border border-dark"
            >
                <div>
                    {{ item.name }}
                </div>
                <i class="fa fa-play" />
                <template v-if="streamSource">
                    <div class="mt-4">
                        <label-on-off
                            :status="streamSource.ping"
                            :icon-on="streamSource.type === 'webcam' ? 'fa-camera' : 'fa-desktop'"
                            :icon-off="streamSource.type === 'webcam' ? 'fa-camera' : 'fa-desktop'"
                            :text-on="streamSource.speaker.name"
                            :text-off="streamSource.speaker.name"
                        />
                    </div>
                </template>
            </div>
        </template>
        <template v-else-if="['image_source', 'color_source_v2', 'text_ft2_source_v2', 'text_gdiplus_v2', 'ffmpeg_source'].includes(item.type)">
            <img :src="item.settings.img" class="w-100" />
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import LabelOnOff from '../StreamProducer/LabelOnOff'

    export default {
        name: 'ScenePreviewItem',
        props: {
            item: {
                type: Object,
                required: true
            },
            width: {
                type: Number,
                required: false,
                default: 0
            },
            height: {
                type: Number,
                required: false,
                default: 0
            },
            xPosition: {
                type: Number,
                required: false,
                default: 0
            },
            yPosition: {
                type: Number,
                required: false,
                default: 0
            },
            rotation: {
                type: Number,
                required: false,
                default: 0
            }
        },
        components: { LabelOnOff },
        data () {
            return {
                visibility: this.item.properties.visible
            }
        },
        computed: {
            ...mapState({
                meetup: state => state.meetup,
                pings: state => state.pings
            }),
            streamSource () {
                if ( this.item.type === 'browser_source' ) {
                    for ( const stream of this.meetup.participants_streams ) {
                        if ( this.item.settings.url.includes(stream.stream_key) || this.item.settings.url.includes(`${this.meetup.jitsi_password}/${stream.id}`) ) {
                            return this.getStreamSource(stream)
                        }
                    }
                }

                return null
            },
            style () {
                return {
                    maxWidth: '100%',
                    width: `${this.width}px`,
                    height: `${this.height}px`,
                    top: `${this.yPosition}px`,
                    left: `${this.xPosition}px`,
                    transform: `rotate(${this.rotation}deg)`,
                    transformOrigin: 'top left'
                }
            }
        },
        methods: {
            changeVisibility ( visibility ) {
                this.visibility = visibility
            },
            getStreamSource ( speaker ) {
                const type = this.item.settings.url.includes(`${speaker.stream_key}`) ? 'desktop' : 'webcam'
                const ping = this.getPing(speaker.id)
                return {
                    speaker: speaker,
                    type,
                    ping: type === 'webcam' ? ping.ping_webcam : ping.ping_desktop
                }
            },
            getPing ( user ) {
                const filter = this.pings.filter(ping => ping.user === user)

                return {
                    ping_webcam: filter.length ? filter[0].webcam : false,
                    ping_desktop: filter.length ? filter[0].desktop : false,
                }
            }
        }
    }
</script>

<style scoped>
    .preview-source {
        color: white;
        position: absolute;
    }

    .preview-source.is-hide {
        opacity: 0;
    }

    .preview-stream {
        padding-top: 10%;
    }

    .preview-stream i {
        font-size: 2rem;
        margin-top: 20px;
    }
</style>
