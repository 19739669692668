<template>
    <div class="row">
        <div
            v-for="stream in streams"
            :key="`source-filter-${stream.id}`"
            class="col-xs-6 col-md-3"
        >
            <div class="bg-primary text-white text-center p-1">
                <i
                    v-if="meetup.presenter_id === stream.id"
                    class="fa fa-star"
                />
                {{ stream.name }}
                <label-on-off
                    :status="stream.ping_webcam"
                    icon-on="fa-camera"
                    icon-off="fa-camera"
                />
                <label-on-off
                    :status="stream.ping_desktop"
                    icon-on="fa-desktop"
                    icon-off="fa-desktop"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LabelOnOff from '../StreamProducer/LabelOnOff'

export default {
    name: 'StreamRoomSources',
    props: {
        meetup: {
            type: Object,
            required: true
        },
        auth: {
            type: Object,
            required: true
        },
        pingsRoute: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        }
    },
    components: { LabelOnOff },
    data () {
        return {
            pings: []
        }
    },
    computed: {
        streams () {
            const streams = this.meetup.streams.map(stream => {
                return {
                    ...stream.speaker,
                    ...this.getPing(stream.speaker.id)
                }
            })

            if ( this.meetup.presenter && !streams.some(stream => stream.id === this.meetup.presenter.id) ) {
                streams.push({
                    ...this.meetup.presenter,
                    ...this.getPing(this.meetup.presenter.id)
                })
            }

            return streams
        }
    },
    mounted () {
        this.checkPings()
    },
    methods: {
        getIsMe ( speaker ) {
            return speaker.id === this.auth.id
        },
        checkPings () {
            fetch(this.pingsRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _token: this.token
                })
            })
                .then(response => response.json())
                .then(({ status, data }) => {
                    if ( status ) {
                        this.pings = data
                    }
                })

            setTimeout(this.checkPings, 10000)
        },
        getPing ( user ) {
            const filter = this.pings.filter(ping => ping.user === user)

            return {
                ping_webcam: filter.length ? filter[0].webcam : false,
                ping_desktop: filter.length ? filter[0].desktop : false,
            }
        }
    }
}
</script>
