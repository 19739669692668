<template>
    <div>
        <div class="bg-light shadow p-2 border-bottom">
            <div class="row">
                <div :class="{'col-2': roomPrimaryView !== 'chat', 'col-1': roomPrimaryView === 'chat'}">
                    <button
                        class="btn btn-sm btn-link p-1"
                        @click="toggleRoomPrimaryView"
                    >
                        <i
                            class="fa fa-caret-left"
                            :class="{'fa-caret-left': roomPrimaryView === 'jitsi', 'fa-caret-right': roomPrimaryView === 'chat'}"
                        />
                    </button>
                </div>
                <div
                    :class="{'col-10': roomPrimaryView !== 'chat', 'col-11': roomPrimaryView === 'chat'}"
                    class="text-right"
                >

                    <div
                        v-if="roomPrimaryView === 'chat'"
                        class="d-inline-flex mr-2"
                    >
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa fa-search" />
                                </span>
                            </div>
                            <input
                                v-model="filterWriter"
                                type="text"
                                class="form-control"
                                :placeholder="$t('Filter writer')"
                            />
                        </div>
                    </div>
                    <div class="custom-control custom-switch d-inline-flex">
                        <input
                            v-model="filter"
                            type="checkbox"
                            class="custom-control-input"
                            id="switchFilter"
                            @change="toggleWriterInMeetup"
                        />
                        <label class="custom-control-label" for="switchFilter">
                            <small>{{ $t('Writers in meetup') }}</small>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-light shadow" style="height: 75vh">
            <div id="questionsContainer" ref="questionsContainer" class="h-100 p-2">
                <div
                    v-if="writers.length"
                    class="list-group"
                >
                    <writer
                        v-for="writer in writersFiltered"
                        :key="`writer-${writer.id}`"
                        :writer="writer"
                        :writer-update-route="writerUpdateRoute.replace(':writer', writer.id)"
                        :csrf_token="csrf_token"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Writer from './Writer'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'Writers',
    props: {
        meetup: {
            type: Object,
            required: true
        },
        writersRequest: {
            type: String,
            required: false,
            default: null
        },
        writerUpdateRoute: {
            type: String,
            required: false,
            default: null
        },
        csrf_token: {
            type: String,
            required: false,
            default: null
        }
    },
    components: { Writer },
    data () {
        return {
            filterWriter: '',
            filter: false,
            writers: []
        }
    },
    computed: {
        ...mapState({
            roomPrimaryView: state => state.roomPrimaryView,
            roomWriterInMeetup: state => state.roomWriterInMeetup
        }),
        writersFiltered () {
            return this.writers.filter(writer => {
                return writer.name.includes(this.filterWriter) || writer.email.includes(this.filterWriter)
            })
        }
    },
    mounted () {
        this.filter = this.roomWriterInMeetup
        this.getWritersRequest()
    },
    methods: {
        ...mapActions({
            toggleRoomPrimaryView: 'toggleRoomPrimaryView',
            toggleWriterInMeetup: 'toggleWriterInMeetup'
        }),
        async getWritersRequest () {
            try {
                fetch(this.writersRequest, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.csrf_token,
                        meetup: this.roomWriterInMeetup ? this.meetup.id : null
                    })
                })
                    .then(response => response.json())
                    .then(({ status, data }) => {
                        if ( status ) {
                            this.writers = data
                        }

                        setTimeout(this.getWritersRequest, 3000)
                    })
            } catch (e){}
        }
    }
}
</script>
