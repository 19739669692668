export default {
    methods: {
        async toggleBlockRequest (route, _token, blocker) {
            try {
                fetch(route, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ _token, blocker })
                })
                    .then(response => response.json())
            } catch (e){}
        }
    }
}
