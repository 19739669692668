<template>
    <div v-if="scene">
        <nav class="navbar navbar-expand-lg navbar-dark bg-light justify-content-end">
            <button
                class="btn btn-primary"
                @click="$emit('play', scene.name)"
            >
                <label-on-off
                    :status="scene.name === currentSceneObs"
                    icon-on="fa-play"
                    icon-off="fa-pause"
                />
                {{ currentView.key }}
            </button>
        </nav>
        <scene-preview
            ref="scenePreview"
            :obs="obs"
            :scene="scene.name"
            :sources="scene.sources.filter(source => !getSourceIsJitsi(source))"
        />

        <div class="mt-2">
            <div class="custom-control custom-switch d-inline-flex mr-2">
                <input
                    id="filterTexts"
                    class="custom-control-input"
                    type="checkbox"
                    :checked="sceneSourcesFilter.texts"
                    @change="toggleSceneSourceFilter('texts')"
                />
                <label class="custom-control-label" for="filterTexts">{{ $t('Texts') }}</label>
            </div>
            <div class="custom-control custom-switch d-inline-flex mr-2">
                <input
                    id="filterImages"
                    class="custom-control-input"
                    type="checkbox"
                    :checked="sceneSourcesFilter.images"
                    @change="toggleSceneSourceFilter('images')"
                />
                <label class="custom-control-label" for="filterImages">{{ $t('Images') }}</label>
            </div>
            <div class="custom-control custom-switch d-inline-flex mr-2">
                <input
                    id="filterVideos"
                    class="custom-control-input"
                    type="checkbox"
                    :checked="sceneSourcesFilter.videos"
                    @change="toggleSceneSourceFilter('videos')"
                />
                <label class="custom-control-label" for="filterVideos">{{ $t('Videos') }}</label>
            </div>
            <div class="custom-control custom-switch d-inline-flex mr-2">
                <input
                    id="filterCameras"
                    class="custom-control-input"
                    type="checkbox"
                    :checked="sceneSourcesFilter.cameras"
                    @change="toggleSceneSourceFilter('cameras')"
                />
                <label class="custom-control-label" for="filterCameras">{{ $t('Cameras') }}</label>
            </div>
            <div class="custom-control custom-switch d-inline-flex mr-2">
                <input
                    id="filterOthers"
                    class="custom-control-input"
                    type="checkbox"
                    :checked="sceneSourcesFilter.others"
                    @change="toggleSceneSourceFilter('others')"
                />
                <label class="custom-control-label" for="filterOthers">{{ $t('Others') }}</label>
            </div>
        </div>
        <hr />
        <scene-source
            v-for="source in sources"
            :key="`scene-${scene.name}source-${source.id}`"
            :obs="obs"
            :scene="scene.name"
            :source="source"
            @sourceUpdated="onSourceUpdated"
        />
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import LabelOnOff from './LabelOnOff'
    import SceneSource from './SceneSource'
    import ScenePreview from './ScenePreview'
    import obsMixin from '../../mixins/obs'

    export default {
        name: 'ContainerScene',
        props: {
            obs: {
                type: Object,
                required: true
            }
        },
        components: { ScenePreview, SceneSource, LabelOnOff },
        mixins: [ obsMixin ],
        computed: {
            ...mapState({
                currentView: state => state.currentView,
                currentSceneObs: state => state.obs.currentScene,
                scenes: state => state.obs.scenes,
                sceneSourcesFilter: state => state.sceneSourcesFilter
            }),
            scene () {
                return this.currentView.key ? this.scenes.find(scene => scene.name === this.currentView.key) : null
            },
            sources () {
                return this.scene.sources.filter(source => {
                    if ( !source.name.includes('(no usar)') ) {
                        switch (source.type) {
                            case 'browser_source':
                                return this.sceneSourcesFilter.cameras
                            case 'text_gdiplus_v2':
                            case 'text_ft2_source_v2':
                                return this.sceneSourcesFilter.texts
                            case 'color_source_v2':
                            case 'image_source':
                                return this.sceneSourcesFilter.images
                            case 'ffmpeg_source':
                                return this.sceneSourcesFilter.videos
                        }
                    }

                    return this.sceneSourcesFilter.others
                })
            }
        },
        methods: {
            ...mapActions({
                toggleSceneSourceFilter: 'toggleSceneSourceFilter'
            }),
            onSourceUpdated () {
                if ( this.currentSceneObs === this.scene.name ) {
                    this.$emit('play', this.scene.name)
                }

                setTimeout(() => {
                    this.$refs.scenePreview.draw()
                }, 500)
            }
        }
    }
</script>
