<template>
    <div class="list-group-item m-1 border">
        <div class="row">
            <div :class="{'col-9': isExpanded, 'col-12': !isExpanded}">
                {{ writer.name }}
                <br>
                <small>{{ writer.email }}</small>
            </div>
            <div
                :class="{'col-3': isExpanded, 'col-12': !isExpanded}"
                class="text-right"
            >
                <button
                    class="btn btn-sm btn-block btn-warning"
                    @click="toggleBlock"
                >
                    <i
                        class="fa fa-ban"
                        :class="{'fa-undo': writer.blocker, 'fa-ban': !writer.blocker}"
                    />
                    {{ $t(writer.blocker ? 'unblock' : 'block') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import writerMixin from '../../mixins/writer'
import { mapState } from 'vuex'

export default {
    name: 'Writer',
    props: {
        writer: {
            type: Object,
            required: true
        },
        writerUpdateRoute: {
            type: String,
            required: false,
            default: null
        },
        csrf_token: {
            type: String,
            required: false,
            default: null
        }
    },
    mixins: [ writerMixin ],
    computed: {
        ...mapState({
            roomPrimaryView: state => state.roomPrimaryView
        }),
        isExpanded () {
            return this.roomPrimaryView === 'chat'
        }
    },
    methods: {
        async toggleBlock () {
            await this.toggleBlockRequest(this.writerUpdateRoute, this.csrf_token, !this.writer.blocker)
        }
    }
}
</script>
