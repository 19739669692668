<template>
    <div class="row">
        <div class="col-12 col-md-6 text-center text-md-left">
            <span class="text-primary">
                {{ meetup.date }}
            </span>
            <a
                v-if="meetup.stream && !meetup.closed"
                :href="meetup.stream"
                target="_blank"
                class="ml-2"
            >
                <i class="fa fa-wifi" aria-hidden="true"></i>
                {{ $t('Youtube')}}
            </a>
        </div>
        <div class="col-12 col-md-6 text-center text-md-right">
            <div v-if="meetup.streams.length" class="d-inline-flex">
                <strong class="mr-1">{{ $t( meetup.streams.length > 1 ? 'Speakers' : 'Speaker')}}: </strong>
                <div
                    v-for="(stream, index) in meetup.streams"
                    :key="`meetup-${meetup.id}-stream-${stream.id}`"
                >
                    <span>{{ index ? `, ${stream.speaker.name}` : `${stream.speaker.name}` }}</span>
                </div>
            </div>
            <div v-if="meetup.presenter" class="d-inline-flex ml-2">
                <strong>{{ $t('Presenter')}}:</strong>
                <span class="ml-1">{{ meetup.presenter.name }}</span>
            </div>
        </div>
        <div class="col-12">
            <hr v-if="meetup.description">
            <p
                v-html="meetup.description"
                class="text-muted text-left"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MeetupData',
    props: {
        meetup: {
            type: Object,
            required: true
        }
    }
}
</script>
