<template>
    <div class="row">
        <div class="col-12 col-md-4">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="streaming-key">
                        <i class="fa fa-key" />
                    </span>
                </div>
                <input
                    v-model="streamingKey.value"
                    type="text"
                    class="form-control form-control-sm"
                    :aria-label="$t('Streaming key...')"
                    :readonly="!streamingKey.editable"
                    aria-describedby="streaming-key"
                />
                <div class="input-group-append">
                    <template v-if="streamingKey.editable">
                        <button class="btn btn-sm btn-danger" @click="handleCancelKey">
                            <i class="fa fa-undo" />
                        </button>
                        <button class="btn btn-sm btn-success" @click="handleUpdateKey">
                            <i class="fa fa-check" />
                        </button>
                    </template>
                    <button v-else class="btn btn-sm btn-primary" @click="streamingKey.editable = true">
                        <i class="fa fa-pencil-alt" />
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="youtube-key">
                        <i class="fa fa-wifi" />
                    </span>
                </div>
                <input
                    v-model="youtubeUrl.value"
                    type="text"
                    class="form-control form-control-sm"
                    :aria-label="$t('Streaming key...')"
                    :readonly="!youtubeUrl.editable"
                    aria-describedby="streaming-key"
                />
                <div class="input-group-append">
                    <template v-if="youtubeUrl.editable">
                        <button class="btn btn-sm btn-danger" @click="handleCancelYoutubeUrl">
                            <i class="fa fa-undo" />
                        </button>
                        <button class="btn btn-sm btn-success" @click="handleUpdateYoutubeUrl">
                            <i class="fa fa-check" />
                        </button>
                    </template>
                    <button v-else class="btn btn-sm btn-primary" @click="youtubeUrl.editable = true">
                        <i class="fa fa-pencil-alt" />
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 text-right">
            <button
                class="btn btn-sm btn-light"
                @click="toggleRecording"
            >
                {{ $t('Room') }}
                <label-on-off
                    :status="obsRecordingStatus"
                    icon-on="fa-pause"
                    icon-off="fa-play"
                />
            </button>
            <button
                class="btn btn-sm btn-light"
                @click="toggleStreaming"
            >
                {{ $t('Youtube') }}
                <label-on-off
                    :status="obsStreamingStatus"
                    icon-on="fa-pause"
                    icon-off="fa-play"
                />
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapAction, mapActions } from 'vuex'
    import LabelOnOff from './LabelOnOff'
    import obsMixin from '../../mixins/obs'

    export default {
        name: 'StreamProducerManage',
        props: {
            meetup: {
                type: Object,
                required: true
            },
            obs: {
                type: Object,
                required: true
            },
            meetupUpdateRoute: {
                type: String,
                required: false,
                default: null
            },
            token: {
                type: String,
                required: false,
                default: null
            }
        },
        components: { LabelOnOff },
        mixins: [ obsMixin ],
        data () {
            return {
                streamingKey: {
                    editable: false,
                    value: ''
                },
                youtubeUrl: {
                    editable: false,
                    old: this.meetup.stream,
                    value: this.meetup.stream
                }
            }
        },
        computed: {
            ...mapState({
                obsRecordingStatus: state => state.obs.recordingStatus,
                obsSettings: state => state.obs.settings,
                obsStreamingStatus: state => state.obs.streamingStatus
            }),
            streamingKeyObs () {
                return this.obsSettings ? this.obsSettings.key : ''
            }
        },
        watch: {
            obsSettings () {
                this.streamingKey.value = this.streamingKeyObs
            }
        },
        methods: {
            ...mapActions({
                setObsSettings: 'setObsSettings'
            }),
            handleCancelKey () {
                this.streamingKey.value = this.streamingKeyObs
                this.streamingKey.editable = false
            },
            handleUpdateKey () {
                const settings = {...this.obsSettings}
                settings.key = this.streamingKey.value
                this.setStreamSettings(settings)
                this.streamingKey.editable = false
                this.refreshSettings()
            },
            handleCancelYoutubeUrl () {
                this.youtubeUrl.value = this.youtubeUrl.old
                this.youtubeUrl.editable = false
            },
            handleUpdateYoutubeUrl () {
                this.youtubeUrl.old = this.youtubeUrl.value
                this.youtubeUrl.editable = false
                this.updateYoutubeUrlRequest(this.youtubeUrl.value)
            },
            async refreshSettings () {
                const settings = await this.getStreamSettings()
                this.setObsSettings(settings)
            },
            updateYoutubeUrlRequest ( value ) {
                try {
                    fetch(this.meetupUpdateRoute, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            _token: this.token,
                            stream: value
                        })
                    })
                        .then(response => response.json())
                } catch (e){}
            }
        }
    }
</script>
