<template>
    <div class="card p-0">
        <stream-room-navbar
            :meetup="meetup"
            :auth="auth"
        />
        <div v-if="meetup.i_am_producer || auth.is_admin" class="m-2">
            <stream-room-sources
                :meetup="meetup"
                :auth="auth"
                :pings-route="pingsRoute"
                :token="token"
            />

            <div v-if="roomSourcesFilter.stream" class="text-center">
                <hr />
                <iframe :src="meetup.server.url" width="560" height="315" frameborder="0" allowfullscreen />
            </div>
        </div>
    </div>
</template>

<script>
import StreamRoomNavbar from './Navbar'
import StreamRoomSources from './Sources'
import { mapState } from 'vuex'

export default {
    name: 'StreamRoom',
    props: {
        meetup: {
            type: Object,
            required: true
        },
        auth: {
            type: Object,
            required: true
        },
        roomRoute: {
            type: String,
            required: true
        },
        pingsRoute: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        }
    },
    components: {
        StreamRoomSources,
        StreamRoomNavbar
    },
    computed: {
        ...mapState({
            roomSourcesFilter: state => state.roomSourcesFilter
        })
    }
}
</script>

