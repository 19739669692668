<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-light">
        <ul  class="navbar-nav mr-auto">
            <li v-if="meetup.i_am_producer || auth.is_admin" class="nav-item">
                <div class="form-inline my-2 my-lg-0">
                    <a
                        :href="$parent.roomRoute"
                        class="btn btn-sm btn-primary mr-2"
                        target="_blank"
                    >
                        <i class="fa fa-home" />
                        {{ $t('Room chat') }}
                    </a>
                    <div class="custom-control custom-switch mr-4">
                        <input
                            :checked="roomSourcesFilter.stream"
                            id="filterStream"
                            type="checkbox"
                            class="custom-control-input"
                            @change="toggleRoomSource('stream')"
                        />
                        <label class="custom-control-label" for="filterStream">
                            {{ $t('Stream') }}
                        </label>
                    </div>
                </div>
            </li>
        </ul>
        <div class="my-2 my-lg-0">
            <label-on-off
                v-if="meetup.i_am_producer || auth.is_admin"
                :status="serverStatus"
                icon-on="fa-home"
                icon-off="fa-home"
                :text-on="`${$t('Room')}`"
                :text-off="`${$t('Room')}`"
            />
            <label-on-off
                :status="streamingStatus"
                icon-on="fa-wifi"
                icon-off="fa-wifi"
                :text-on="`${$t('Youtube')}`"
                :text-off="`${$t('Youtube')}`"
            />
            <span v-if="meetup.i_am_speaker" class="ml-2">
                <a :href="auth.speakerStreams.desktop.share" class="btn btn-primary btn-sm my-2 my-sm-0" target="_blank">
                    <i class="fa fa-desktop" />
                    {{ $t('Share desktop')}}
                </a>
            </span>
        </div>
    </nav>
</template>

<script>
    import LabelOnOff from '../StreamProducer/LabelOnOff'
    import { mapActions, mapState } from 'vuex'
    import obsMixin from '../../mixins/obs'

    export default {
        name: 'StreamRoomNavbar',
        props: {
            meetup: {
                type: Object,
                required: true
            },
            auth: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                obs: null,
                serverStatus: false,
                streamingStatus: false
            }
        },
        components: { LabelOnOff },
        mixins: [ obsMixin ],
        computed: {
            ...mapState({
                roomSourcesFilter: state => state.roomSourcesFilter
            })
        },
        mounted () {
            this.initialize()
        },
        methods: {
            ...mapActions({
                toggleRoomSource: 'toggleRoomSource'
            }),
            async initialize () {
                const { status, obs, error:err } = await this.connectObsApi(this.meetup.server)

                if ( status ) {
                    this.obs = obs

                    await this.getStreamingStatusObs()

                    this.obs.on('Exiting', () => {
                        this.obs = null
                        this.obsWebsocket = null
                        this.streamingStatus = false
                        this.serverStatus = false
                    })
                    this.obs.on('StreamStarted', () => {
                        this.streamingStatus = true
                    })
                    this.obs.on('StreamStopped', () => {
                        this.streamingStatus = false
                    })
                    this.obs.on('RecordingStarted', () => {
                        this.serverStatus = true
                    })
                    this.obs.on('RecordingStopped', () => {
                        this.serverStatus = false
                    })
                } else {
                    this.obsWebsocket = null
                    this.streamingStatus = false
                    this.serverStatus = false
                }
            },
            async getStreamingStatusObs () {
                const { streaming, recording } = await this.getStreamingStatus()
                this.streamingStatus = streaming
                this.serverStatus = recording
            }
        }
    }
</script>
