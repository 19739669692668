<template>
    <div
        class="list-group-item m-1 border"
        :class="{
                'border-success question-answered': message.status === 'answered',
                'border-primary question-viewed': message.status === 'viewed',
                'border-danger question-pending': message.status === 'pending'
        }"
    >
        <div
            v-if="roomPrimaryView === 'chat'"
            class="row text-muted"
        >
            <div class="col-6">
                {{ message.writer.name }}
                <small>{{ message.writer.email }}</small>
            </div>
            <div class="col-6 text-right">
                {{ message.created_at }}
            </div>
        </div>
        <div v-else>
            {{ message.writer.name }}
        </div>
        <hr>
        <div>
            {{ message.content }}
        </div>
        <hr>
        <div class="row">
            <div
                v-if="roomPrimaryView === 'chat'"
                class="col-3"
            >
                <button
                    class="btn btn-sm btn-danger"
                    @click="destroyRequest"
                >
                    <i class="fa fa-trash" />
                </button>
            </div>
            <div :class="{'col-9 text-right': roomPrimaryView === 'chat', 'col-12 text-center': roomPrimaryView !== 'chat'}">
                <div class="btn-group btn-group-sm" role="group" aria-label="Actions">
                    <button
                        class="btn btn-sm btn-warning"
                        @click="toggleBlock"
                    >
                        <i
                            class="fa fa-ban"
                            :class="{'fa-undo': message.writer.blocker, 'fa-ban': !message.writer.blocker}"
                        />
                        {{ $t(message.writer.blocker ? 'unblock' : 'block') }}
                    </button>
                    <div class="btn-group btn-group-sm" role="group">
                        <button
                            id="btnGroupStatus"
                            class="btn btn-primary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i
                                class="fa"
                                :class="{'fa-clock': message.status === 'pending', 'fa-eye': message.status === 'viewed', 'fa-comment-dots': message.status === 'answered'}"
                            />
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupStatus">
                            <button
                                v-if="message.status !== 'pending'"
                                class="dropdown-item"
                                @click="updateRequest('pending')"
                            >
                                <i class="fa fa-clock" />
                                {{ $t('pending') }}
                            </button>
                            <button
                                v-if="message.status !== 'viewed'"
                                class="dropdown-item"
                                @click="updateRequest('viewed')"
                            >
                                <i class="fa fa-eye" />
                                {{ $t('viewed') }}
                            </button>
                            <button
                                v-if="message.status !== 'answered'"
                                class="dropdown-item"
                                @click="updateRequest('answered')"
                            >
                                <i class="fa fa-comment-dots" />
                                {{ $t('answered') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import writerMixin from '../../mixins/writer'
import { mapState } from 'vuex'

export default {
    name: 'Question',
    props: {
        message: {
            type: Object,
            required: true
        },
        messageUpdateRoute: {
            type: String,
            required: false,
            default: null
        },
        messageDestroyRoute: {
            type: String,
            required: false,
            default: null
        },
        writerUpdateRoute: {
            type: String,
            required: false,
            default: null
        },
        csrf_token: {
            type: String,
            required: true
        }
    },
    mixins: [ writerMixin ],
    computed: {
        ...mapState({
            roomPrimaryView: state => state.roomPrimaryView
        })
    },
    methods: {
        async toggleBlock () {
            await this.toggleBlockRequest(this.writerUpdateRoute, this.csrf_token, !this.message.writer.blocker)
        },
        async updateRequest ( status ) {
            try {
                fetch(this.messageUpdateRoute, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.csrf_token,
                        status
                    })
                })
                    .then(response => response.json())
            } catch (e){}
        },
        async destroyRequest () {
            try {
                fetch(this.messageDestroyRoute, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.csrf_token
                    })
                })
                    .then(response => response.json())
            } catch (e){}
        }
    }
}
</script>

<style scoped>
    .question-answered {
        background-color: rgba(57, 193, 113, .01);
    }

    .question-viewed {
        background-color: rgba(187, 10, 124, .01);
    }

    .question-pending {
        background-color: rgba(227, 53, 46, .01);
    }
</style>
