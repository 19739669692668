const OBSWebSocket = require('obs-websocket-js')

const obsMixin = {
    methods: {
        async connectObsApi ({ host, port, password}) {
            try {
                const obs = new OBSWebSocket()
                const hostPort = port ? `:${port}` : ''
                await obs.connect({
                    address: `${host}${hostPort}`,
                    password,
                    secure: location.protocol === 'https:'
                })
                return { status: true, obs }
            } catch ( error ) {
                return { status: false, error }
            }
        },
        async getMute ( source ) {
            const { status, muted } = await this.obs.send('GetMute', { source })

            if ( status === 'ok' ) {
                return muted
            }

            return false
        },
        async getSceneList ()  {
            return await this.obs.send('GetSceneList')
        },
        getSourceIsJitsi ( source ) {
            return source.type === 'browser_source' && source.name.includes('Sound_')
        },
        async getSourceSettings ( sourceName ) {
            const { status, sourceSettings } = await this.obs.send('GetSourceSettings', { sourceName })

            if ( status === 'ok' ) {
                return sourceSettings
            }

            return null
        },
        async getSourceProperties ( scene, item ) {
            const properties = await this.obs.send('GetSceneItemProperties', {
                'scene-name': scene,
                item
            })

            if ( properties.status === 'ok' ) {
                return properties
            }

            return null
        },
        async getStreamSettings () {
            const { settings } = await this.obs.send('GetStreamSettings')
            return settings
        },
        async getStreamingStatus () {
            const { status, streaming, recording } = await this.obs.send('GetStreamingStatus')

            if ( status === 'ok' ) {
                return { streaming, recording }
            }

            return { streaming: false, recording: false }
        },
        async getVideInfo () {
            const videoInfo = await this.obs.send('GetVideoInfo', {})

            if ( videoInfo.status === 'ok' ) {
                return videoInfo
            }

            return null
        },
        async overwriteScenesMuted ( scenes ) {
            for ( const scene of scenes ) {
                for ( const source of scene.sources ) {
                    if (
                        ( this.getSourceIsJitsi(source) && source.muted ) ||
                        ( !this.getSourceIsJitsi(source) && source.type === 'browser_source' && !source.muted )
                    ) {
                        await this.toggleMute(source.name)
                    }
                }
            }
        },
        async overwriteScenesJitsiSound ( scenes, jitsiUrl ) {
            for ( const [index, scene] of scenes.entries() ) {
                for ( const source of scene.sources ) {
                    if ( this.getSourceIsJitsi(source) ) {
                        const settings = await this.getSourceSettings(source.name)
                        const url = jitsiUrl.replace(':name', `OBS-SOUND`)
                        console.log(source, settings)

                        if ( url !== jitsiUrl ) {
                            this.setSourceSettings(source.name, source.type, { ...settings, url })
                        }
                    }
                }
            }
        },
        setCurrentScene ( scene ) {
            this.obs.send('SetCurrentScene', {
                'scene-name': scene
            })
        },
        async setSceneItemProperties ( scene, item, properties ) {
            await this.obs.send('SetSceneItemProperties', {
                'scene-name': scene,
                item,
                ...properties
            })
        },
        setSourceSettings ( sourceName, sourceType, sourceSettings ) {
            this.obs.send('SetSourceSettings',  { sourceName, sourceType, sourceSettings })
        },
        setStreamSettings ( settings ) {
            this.obs.send('SetStreamSettings', { settings })
        },
        toggleStreaming () {
            this.obs.send('StartStopStreaming', {})
        },
        toggleRecording () {
            this.obs.send('StartStopRecording', {})
        },
        async toggleMute ( source ) {
            this.obs.send('ToggleMute', { source })
        }
    }
}

export default obsMixin
