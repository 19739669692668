/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vuex from 'vuex'
import storeData from './store/index'
import VueI18n from 'vue-i18n'
import translations from './vue-i18n-locales.generated'
import createPersistedState  from  'vuex-persistedstate'
import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueI18n)
Vue.use(Vuex)
Vue.use(VueConfirmDialog)

const i18n = new VueI18n({
    locale: document.documentElement.lang.substr(0, 2),
    messages: translations
})

const store = new Vuex.Store({
    ...storeData,
    plugins: [createPersistedState({
        paths: [
            'currentView',
            'roomSourcesFilter',
            'sceneSourcesFilter',
            'sourceCollapse',
            'showPreview',
            'guest',
            'roomPrimaryView',
            'roomSecondaryView',
            'roomWriterInMeetup',
            'roomMessageFilters'
        ]
    })]
})

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('stream-room', require('./components/StreamRoom/Index.vue').default);
Vue.component('stream-speaker', require('./components/StreamSpeaker/Index.vue').default);
Vue.component('stream-producer', require('./components/StreamProducer/Index.vue').default);
Vue.component('jitsi-meet', require('./components/Jitsi/Meet.vue').default);
Vue.component('Meetups', require('./components/Public/Meetups.vue').default);
Vue.component('Meetup', require('./components/Public/Meetup.vue').default);
Vue.component('RichText', require('./components/RichText.vue').default);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
    el: '#app',
    i18n,
    store
});
