<template>
    <div class="card p-4">
        <div class="row">
            <div class="col-6">
                <h2>{{ $t('Producer') }} - <strong>{{ meetup.name }}</strong></h2>
            </div>
        </div>

        <hr />

        <connect
            :jitsi-url="meetup.jitsi_url"
            :streams="meetup.streams"
            @obsLoaded="onObsLoaded"
        />

        <template v-if="obsStatus">
            <manage
                :meetup="meetup"
                :obs="obs"
                :meetup-update-route="meetupUpdateRoute"
                :token="token"
            />

            <div class="row mt-4">
                <div
                    v-show="!sourceCollapse"
                    class="col-12 col-md-4"
                >
                    <side-bar />
                </div>
                <div
                    class="col-12"
                    :class="{'col-md-8': !sourceCollapse, 'col-md-12': sourceCollapse}"
                >
                    <container-scene
                        v-if="currentView.type === 'scene'"
                        :obs="obs"
                        @play="onPlayScene"
                    />
                    <container-room
                        v-else-if="currentView.type === 'room'"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import Connect from './Connect'
    import Manage from './Manage'
    import SideBar from './SideBar'
    import ContainerScene from './ContainerScene'
    import ContainerRoom from './ContainerRoom'
    import obsMixin from '../../mixins/obs'

    export default {
        name: 'StreamProducer',
        props: {
            meetup: {
                type: Object,
                required: true
            },
            roomRoute: {
                type: String,
                required: true
            },
            controlRoute: {
                type: String,
                required: true
            },
            meetupUpdateRoute: {
                type: String,
                required: false,
                default: null
            },
            pingsRoute: {
                type: String,
                required: true
            },
            token: {
                type: String,
                required: false,
                default: null
            }
        },
        components: { ContainerRoom, ContainerScene, SideBar, Connect, Manage },
        mixins: [ obsMixin ],
        data () {
            return {
                stream: this.meetup.stream,
                obs: null
            }
        },
        computed: {
            ...mapState({
                currentView: state => state.currentView,
                obsStatus: state => state.obs.status,
                scenes: state => state.obs.scenes,
                sourceCollapse: state => state.sourceCollapse
            })
        },
        mounted () {
            this.setMeetup(this.meetup)
            this.setObsConnect(this.meetup.server)
            this.checkPings()
        },
        methods: {
            ...mapActions({
                setMeetup: 'setMeetup',
                setObsConnect: 'setObsConnect',
                setPings: 'setPings',
                setSourceCollapse: 'setSourceCollapse'
            }),
            onObsLoaded ( obs ) {
                this.obs = obs
            },
            onPlayScene ( scene ) {
                this.setCurrentScene(scene)
            },
            handleToggleSourceCollapse () {
                this.setSourceCollapse(!this.sourceCollapse)
            },
            checkPings () {
                fetch(this.pingsRoute, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _token: this.token
                    })
                })
                    .then(response => response.json())
                    .then(({ status, data }) => {
                        if ( status ) {
                            this.setPings(data)
                        }
                    })

                setTimeout(this.checkPings, 10000)
            },
        }
    }
    </script>
