<template>
    <div
        class="badge p-1"
        :class="{'badge-success': status, 'badge-danger': !status}"
    >
        <template v-if="status">
            <template v-if="iconOn">
                <i :class="`fa ${iconOn}`" />
                <template v-if="textOn">
                    {{ textOn }}
                </template>
            </template>
            <template v-else-if="textOn">
                {{ textOn }}
            </template>
            <template v-else>
                on
            </template>
        </template>
        <template v-else>
            <template v-if="iconOff">
                <i :class="`fa ${iconOff}`" />
                <template v-if="textOff">
                    {{ textOff }}
                </template>
            </template>
            <template v-else-if="textOff">
                {{ textOff }}
            </template>
            <template v-else>
                off
            </template>
        </template>
    </div>
</template>

<script>
	export default {
		name: 'LabelOnOff',
        props: {
		    status: {
		        type: Boolean,
                required: false,
                default: false
            },
            iconOn: {
                type: String,
                required: false,
                default: null
            },
            textOn: {
		        type: String,
                required: false,
                default: null
            },
            textOff: {
                type: String,
                required: false,
                default: null
            },
            iconOff: {
                type: String,
                required: false,
                default: null
            }
        }
	}
</script>

<style scoped>
    .badge {
        min-width: 20px;
        text-align: center;
    }
</style>
