export default {
    "en": {
        "Action": "Action",
        "Add": "Add",
        "Close": "Close",
        "Create": "Create",
        "Date": "Date",
        "Delete": "Delete",
        "Desktop": "Desktop",
        "Disable": "Disable",
        "Edit": "Edit",
        "Enable": "Enable",
        "Go": "Go",
        "Latest Meetups": "Latest Meetups",
        "Login": "Login",
        "Meetups": "Meetups",
        "Meetup Name": "Meetup name",
        "Name": "Name",
        "Open": "Open",
        "Producer": "Producer",
        "Register": "Register",
        "Reopen": "Reopen",
        "Share desktop": "Share desktop",
        "Share webcam": "Share webcam",
        "Select a speaker...": "Select a speaker...",
        "Speaker": "Speaker",
        "Speakers": "Speakers",
        "Status": "Status",
        "Stream": "Stream",
        "Update": "Update",
        "View": "View",
        "Webcam": "Webcam",
        "Host": "Host",
        "Password": "Password",
        "Connect": "Connect",
        "Broadcast controller": "Broadcast controller",
        "Streaming key...": "Streaming key...",
        "Streaming": "Streaming",
        "Refresh": "Refresh",
        "Users": "Users",
        "Servers": "Servers",
        "Administrator": "Administrator",
        "Account": "Account",
        "Select a server": "Select a serve",
        "Remove": "Remove",
        "Password confirmation": "Password confirmation",
        "Port": "Port",
        "Speakers not found": "Speakers not found",
        "Scene": "Scene",
        "Show preview": "Show preview",
        "Hide preview": "Hide preview",
        "Empty": "Empty",
        "text": "text",
        "image": "image",
        "Speaker without meetup": "Speaker without meetup",
        "Connect to": "Connect to",
        "server": "server",
        "OBS has been closed.": "OBS has been closed.",
        "Connection error.": "Connection error.",
        "E-Mail Address": "Email",
        "Remember Me": "Remember me",
        "Forgot Your Password?": "Forgot your password?",
        "Send Password Reset Link": "Send password reset link",
        "Confirm Password": "Confirm password",
        "Refresh room": "Refresh room",
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "app": {
            "Add": "Add",
            "Login": "Login",
            "Meetups": "Meetups",
            "Register": "Register",
            "Update": "Update"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "messages": {
            "user": {
                "created": "User has been created.",
                "updated": "User has been updated.",
                "deleted": "User has been deleted.",
                "confirmation": "Are you sure you want to delete the user?",
                "refreshedUrls": "Streaming urls has been refreshed"
            },
            "server": {
                "created": "Server has been created.",
                "updated": "Server has been updated."
            },
            "stream": {
                "resultsNotFound": "Streams not found in currrent Meetup",
                "created": "Stream has been created.",
                "updated": "Stream has been updated.",
                "error": {
                    "alreadyExists": "Stream already exists",
                    "unauthorized": "Unauthorized"
                }
            },
            "account": {
                "confirmDelete": "Are you sure you want to delete the account?"
            },
            "meetups": {
                "created": "Meetups has been created",
                "updated": "Meetups has been updated"
            }
        }
    },
    "es": {
        "Action": "Acción",
        "Add": "Añadir",
        "Close": "Cerrar",
        "Create": "Crear",
        "Date": "Fecha",
        "Delete": "Eliminar",
        "Desktop": "Escritorio",
        "Disable": "Desactivado",
        "Edit": "Editar",
        "Enable": "Activado",
        "Go": "Ir",
        "Latest Meetups": "Últimos Meetings",
        "Login": "Iniciar sesión",
        "Logout": "Cerrar sesión",
        "Meetups": "Meetings",
        "Meetup Name": "Nombre Meeting",
        "Name": "Nombre",
        "Open": "Abierto",
        "Producer": "Productor",
        "Register": "Registro",
        "Reopen": "Reabrir",
        "Share desktop": "Compartir escritorio",
        "Share webcam": "Compartir webcam",
        "Select a speaker...": "Seleccionar un ponente...",
        "Speaker": "Ponente",
        "Speakers": "Ponentes",
        "Status": "Estado",
        "Stream": "Stream",
        "Update": "Actualizar",
        "View": "Ver",
        "Webcam": "Webcam",
        "Host": "Host",
        "Password": "Contraseña",
        "Connect": "Conectar",
        "Broadcast controller": "Controlador broadcast",
        "Streaming key...": "Clave streaming...",
        "Streaming": "Streaming",
        "Refresh": "Refrescar",
        "Users": "Usuarios",
        "Servers": "Servidores",
        "Administrator": "Administrador",
        "Account": "Cuenta",
        "Select a server": "Selecciona un servidor",
        "Remove": "Eliminar",
        "Password confirmation": "Confirmación de contraseña",
        "Port": "Puerto",
        "Speakers not found": "Ponentes no encontrados",
        "Scene": "Escena",
        "Show preview": "Mostrar previsualización",
        "Hide preview": "Ocultar previsualización",
        "Empty": "Vacío",
        "text": "texto",
        "image": "imagen",
        "Speaker without meetup": "Ponente fuera del meeting",
        "Connect to": "Conectar con",
        "server": "servidor",
        "OBS has been closed.": "Obs ha sido cerrado.",
        "Connection error.": "Error de conexión.",
        "E-Mail Address": "Email",
        "Remember Me": "Recordarme",
        "Forgot Your Password?": "¿Has perdido tu contraseña?",
        "Send Password Reset Link": "Enviar enlace para reiniciar la contraseña",
        "Confirm Password": "Confirmar contraseña",
        "Presenter": "Presentador",
        "Room": "Sala",
        "Cameras": "Cámaras",
        "Texts": "Textos",
        "Others": "Otros",
        "Images": "Imágenes",
        "Refresh room": "Refrescar sala",
        "Room chat": "Sala de charla",
        "Room control": "Sala de control",
        "Start date": "Fecha de inicio",
        "Description": "Descripción",
        "Server": "Servidor",
        "Closed": "Cerrados",
        "Opened": "Abiertos",
        "All": "Todos",
        "Sorry, you has been banned": "Lo siento, has sido bloqueado",
        "Message": "Mensaje",
        "Messages": "Mensajes",
        "Send": "Enviar",
        "Message has been sent": "Mensaje enviado",
        "Save": "Guardar",
        "pending": "pendiente",
        "viewed": "visto",
        "answered": "contestado",
        "Writers": "Escritores",
        "writers": "escritores",
        "Writers in meetup": "Participantes del meeting",
        "block": "bloquear",
        "unblock": "desbloquear",
        "blockers": "bloqueados",
        "Filter writer": "Filtrar escritores",
        "Filter messages": "Filtrar mensajes",
        "Do you want to ask a question?": "¿Quieres hacer una pregunta?",
        "Phone": "Teléfono",
        "If you have any technical problem you can use chat to talk with producer or call to": "Si tienes algún problema técnico puedes utilizar el chat para hablar con el productor o puedes llamar al",
        "If you specify a campus url the stream will be emitted in that url, if it is left empty it will be emitted internally in this platform": "Si especificas una url del campus el stream se emitirá en dicha url, si se deja vacío se emitirá de forma interna en esta plataforma",
        "No scale": "Sin escalado",
        "Scale": "Escalado",
        "No codec": "Sin codec",
        "Yes": "Sí",
        "If you refresh this room all users will be rejected to enter the new room and during this time the audio will be lost in the broadcast. Are you sure?": "Si refrescas esta sala todos los usuarios serán expulsados para entrar en la nueva sala y durante este tiempo se perderá el audio en la retransmisión. ¿Estás seguro?",
        "Remember to mute the stream so that there are no sound problems in the broadcast.": "Recuerda silenciar el stream para que no se produzcan problemas de sonido en la emisión.",
        "passwords": {
            "reset": "¡Tu contraseña ha sido reiniciada!",
            "sent": "¿Hemos enviado un email con el enlace para reiniciar la contraseña!",
            "throttled": "Por favor, espera antes de volver a intentarlo.",
            "token": "Esta contraseña de reinicio de token es inválida.",
            "user": "No podemos encontrar un usuario con esta dirección de email."
        },
        "auth": {
            "failed": "Estas credenciales no concuerdan con nuestros registros.",
            "throttle": "Muchos intentos de inicio de sesión. Por favor prueba otra vez en {seconds} segundos."
        },
        "app": {
            "Add": "Añadir",
            "Login": "Iniciar sesión",
            "Meetups": "Meetups",
            "Register": "Registro",
            "Update": "Actualizar"
        },
        "pagination": {
            "previous": "&laquo; Anterior",
            "next": "Siguiente &raquo;"
        },
        "validation": {
            "accepted": "El campo {attribute} debe ser aceptado.",
            "active_url": "El campo {attribute} no es una URL válida.",
            "after": "El campo {attribute} debe ser una fecha posterior a {date}.",
            "after_or_equal": "El campo {attribute} debe ser una fecha posterior o igual a {date}.",
            "alpha": "El campo {attribute} solo puede contener letras.",
            "alpha_dash": "El campo {attribute} solo puede contener letras, números, guiones y guiones bajos.",
            "alpha_num": "El campo {attribute} solo puede contener letras y números.",
            "array": "El campo {attribute} debe ser un array.",
            "before": "El campo {attribute} debe ser una fecha anterior a {date}.",
            "before_or_equal": "El campo {attribute} debe ser una fecha anterior o igual a {date}.",
            "between": {
                "numeric": "El campo {attribute} debe ser un valor entre {min} y {max}.",
                "file": "El archivo {attribute} debe pesar entre {min} y {max} kilobytes.",
                "string": "El campo {attribute} debe contener entre {min} y {max} caracteres.",
                "array": "El campo {attribute} debe contener entre {min} y {max} elementos."
            },
            "boolean": "El campo {attribute} debe ser verdadero o falso.",
            "confirmed": "El campo confirmación de {attribute} no coincide.",
            "date": "El campo {attribute} no corresponde con una fecha válida.",
            "date_equals": "El campo {attribute} debe ser una fecha igual a {date}.",
            "date_format": "El campo {attribute} no corresponde con el formato de fecha {format}.",
            "different": "Los campos {attribute} y {other} deben ser diferentes.",
            "digits": "El campo {attribute} debe ser un número de {digits} dígitos.",
            "digits_between": "El campo {attribute} debe contener entre {min} y {max} dígitos.",
            "dimensions": "El campo {attribute} tiene dimensiones de imagen inválidas.",
            "distinct": "El campo {attribute} tiene un valor duplicado.",
            "email": "El campo {attribute} debe ser una dirección de correo válida.",
            "ends_with": "El campo {attribute} debe finalizar con alguno de los siguientes valores: {values}",
            "exists": "El campo {attribute} seleccionado no existe.",
            "file": "El campo {attribute} debe ser un archivo.",
            "filled": "El campo {attribute} debe tener un valor.",
            "gt": {
                "numeric": "El campo {attribute} debe ser mayor a {value}.",
                "file": "El archivo {attribute} debe pesar más de {value} kilobytes.",
                "string": "El campo {attribute} debe contener más de {value} caracteres.",
                "array": "El campo {attribute} debe contener más de {value} elementos."
            },
            "gte": {
                "numeric": "El campo {attribute} debe ser mayor o igual a {value}.",
                "file": "El archivo {attribute} debe pesar {value} o más kilobytes.",
                "string": "El campo {attribute} debe contener {value} o más caracteres.",
                "array": "El campo {attribute} debe contener {value} o más elementos."
            },
            "image": "El campo {attribute} debe ser una imagen.",
            "in": "El campo {attribute} es inválido.",
            "in_array": "El campo {attribute} no existe en {other}.",
            "integer": "El campo {attribute} debe ser un número entero.",
            "ip": "El campo {attribute} debe ser una dirección IP válida.",
            "ipv4": "El campo {attribute} debe ser una dirección IPv4 válida.",
            "ipv6": "El campo {attribute} debe ser una dirección IPv6 válida.",
            "json": "El campo {attribute} debe ser una cadena de texto JSON válida.",
            "lt": {
                "numeric": "El campo {attribute} debe ser menor a {value}.",
                "file": "El archivo {attribute} debe pesar menos de {value} kilobytes.",
                "string": "El campo {attribute} debe contener menos de {value} caracteres.",
                "array": "El campo {attribute} debe contener menos de {value} elementos."
            },
            "lte": {
                "numeric": "El campo {attribute} debe ser menor o igual a {value}.",
                "file": "El archivo {attribute} debe pesar {value} o menos kilobytes.",
                "string": "El campo {attribute} debe contener {value} o menos caracteres.",
                "array": "El campo {attribute} debe contener {value} o menos elementos."
            },
            "max": {
                "numeric": "El campo {attribute} no debe ser mayor a {max}.",
                "file": "El archivo {attribute} no debe pesar más de {max} kilobytes.",
                "string": "El campo {attribute} no debe contener más de {max} caracteres.",
                "array": "El campo {attribute} no debe contener más de {max} elementos."
            },
            "mimes": "El campo {attribute} debe ser un archivo de tipo: {values}.",
            "mimetypes": "El campo {attribute} debe ser un archivo de tipo: {values}.",
            "min": {
                "numeric": "El campo {attribute} debe ser al menos {min}.",
                "file": "El archivo {attribute} debe pesar al menos {min} kilobytes.",
                "string": "El campo {attribute} debe contener al menos {min} caracteres.",
                "array": "El campo {attribute} debe contener al menos {min} elementos."
            },
            "not_in": "El campo {attribute} seleccionado es inválido.",
            "not_regex": "El formato del campo {attribute} es inválido.",
            "numeric": "El campo {attribute} debe ser un número.",
            "password": "La contraseña es incorrecta.",
            "present": "El campo {attribute} debe estar presente.",
            "regex": "El formato del campo {attribute} es inválido.",
            "required": "El campo {attribute} es obligatorio.",
            "required_if": "El campo {attribute} es obligatorio cuando el campo {other} es {value}.",
            "required_unless": "El campo {attribute} es requerido a menos que {other} se encuentre en {values}.",
            "required_with": "El campo {attribute} es obligatorio cuando {values} está presente.",
            "required_with_all": "El campo {attribute} es obligatorio cuando {values} están presentes.",
            "required_without": "El campo {attribute} es obligatorio cuando {values} no está presente.",
            "required_without_all": "El campo {attribute} es obligatorio cuando ninguno de los campos {values} están presentes.",
            "same": "Los campos {attribute} y {other} deben coincidir.",
            "size": {
                "numeric": "El campo {attribute} debe ser {size}.",
                "file": "El archivo {attribute} debe pesar {size} kilobytes.",
                "string": "El campo {attribute} debe contener {size} caracteres.",
                "array": "El campo {attribute} debe contener {size} elementos."
            },
            "starts_with": "El campo {attribute} debe comenzar con uno de los siguientes valores: {values}",
            "string": "El campo {attribute} debe ser una cadena de caracteres.",
            "timezone": "El campo {attribute} debe ser una zona horaria válida.",
            "unique": "El valor del campo {attribute} ya está en uso.",
            "uploaded": "El campo {attribute} no se pudo subir.",
            "url": "El formato del campo {attribute} es inválido.",
            "uuid": "El campo {attribute} debe ser un UUID válido.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "messages": {
            "user": {
                "created": "El usuario ha sido creado.",
                "updated": "El usuario ha sido actualizado.",
                "deleted": "El usuario ha sido eliminado.",
                "confirmation": "¿Estas seguro de querer eliminar el usuario?",
                "refreshedUrls": "Las urls del streaming han sido refrescadas"
            },
            "server": {
                "created": "El servidor ha sido creado.",
                "updated": "El servidor ha sido actualizado."
            },
            "stream": {
                "resultsNotFound": "No se han encontrado streams in el meetup actual",
                "created": "El stream ha sido creado.",
                "updated": "El stream ha sido actualizado.",
                "error": {
                    "alreadyExists": "El stream ya existe",
                    "unauthorized": "Desautorizado"
                }
            },
            "account": {
                "confirmDelete": "¿Estas seguro que quieres eliminar la cuenta?"
            },
            "meetups": {
                "created": "El meeting ha sido creado",
                "updated": "El meeting ha sido actualizado"
            }
        }
    }
}
